<template>
  <UzimaUg v-if="partnerCode==='uzima_ug'" />
  <NileUg v-else-if="partnerCode==='nile_ug'" />
  <Default v-else />
</template>

<script>
import Default from '@/views/login/partners/Default';
import UzimaUg from '@/views/login/partners/UzimaUg';
import NileUg from '@/views/login/partners/NileUg';

export default {
  components: { Default, UzimaUg, NileUg },
  computed: {
    partnerCode() { return this.$store.getters.settings.partnerCode; },
  },
  mounted() { this.$i18n.locale = this.language; }
};
</script>
