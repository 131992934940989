<template>
  <PageHeader
    :title="$t('Harvest')"
    save
    @save="saveForm"
    :valid="true"
  >
    <v-form
      ref="form"
      v-model="valid"
      class="green lighten-5"
      v-bind:style="{ width: '100%' }"
    >
      <v-card-title>REGISTER</v-card-title>
      <v-divider></v-divider>
      <v-container fluid>
        <v-row>
          <v-col cols="12" md="4" class="d-flex">
            <v-select
              persistent-hint
              v-model="formFields.learningMaterial"
              label="1 Instructivos Preparación Terreno"
              hint="Instructivos Preparación Terreno"
              :rules="[sharedValidations.requiredValidation]"
              required
            ></v-select>
            <v-btn elevation="2">View Document</v-btn>
          </v-col>
        </v-row>

        <v-row>
          <!--2-->
          <v-col cols="12" md="3">
            <DateInput
              persistent-hint
              v-model="formFields.dateOfFlowering"
              label="2 ¿Fecha inicio floraciones? "
              hint="¿Fecha inicio floraciones? "
              required
              :min="
                new Date(Date.now() - 24 * 30 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .substr(0, 10)
              "
              :max="
                new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .substr(0, 10)
              "
              :rules="[sharedValidations.requiredValidation]"
            />
          </v-col>

          <!--3-->
          <v-col cols="12" md="3">
            <DateInput
              persistent-hint
              v-model="formFields.estimatedDateOfHarvestStart"
              label="3 ¿Fecha estimada de inicio Cosecha? "
              hint="¿Fecha estimada de inicio Cosecha? "
              :min="
                new Date(Date.now() - 24 * 30 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .substr(0, 10)
              "
              :max="
                new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .substr(0, 10)
              "
              :rules="[sharedValidations.requiredValidation]"
            />
          </v-col>

          <v-col cols="12" md="3">
            <v-text-field
              persistent-hint
              v-model="formFields.estimatedFarmLaborForPicking"
              label="4 ¿Mano de Obra estimada para Cosecha (Por Hectárea)?"
              hint="¿Mano de Obra estimada para Cosecha (Por Hectárea)?"
              type="number"
              :rules="[sharedValidations.requiredValidation]"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <DateInput
              persistent-hint
              v-model="formFields.realDateOfHarvestStart"
              label="5 ¿Fecha real de inicio Cosecha?"
              hint="¿Fecha real de inicio Cosecha?"
              :rules="[sharedValidations.requiredValidation]"
              required
              :min="
                new Date(Date.now() - 24 * 30 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .substr(0, 10)
              "
              :max="
                new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)
                  .toISOString()
                  .substr(0, 10)
              "
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </PageHeader>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import DateInput from "../../../../components/DateInput";
import {
  HugoValidations,
  requiredValidation,
} from "./validations/basicValidations";

export default {
  components: { PageHeader, DateInput },
  name: "Phase4View",
  data() {
    return {
      valid: false,
      formFields: {},
      formValidations: {
        nameRules: [requiredValidation],
      },
      sharedValidations: new HugoValidations(),
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    saveForm() {
      this.validate();
    },
    submitSectionForm() {
      console.log("results", this.values);
    },
  },
};
</script>

<style scoped>
</style>
