var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageHeader',{attrs:{"title":_vm.$t('Harvest'),"save":"","valid":true},on:{"save":_vm.saveForm}},[_c('v-form',{ref:"form",staticClass:"green lighten-5",style:({ width: '100%' }),model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_vm._v("REGISTER")]),_c('v-divider'),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","md":"4"}},[_c('v-select',{attrs:{"persistent-hint":"","label":"1 Instructivos Preparación Terreno","hint":"Instructivos Preparación Terreno","rules":[_vm.sharedValidations.requiredValidation],"required":""},model:{value:(_vm.formFields.learningMaterial),callback:function ($$v) {_vm.$set(_vm.formFields, "learningMaterial", $$v)},expression:"formFields.learningMaterial"}}),_c('v-btn',{attrs:{"elevation":"2"}},[_vm._v("View Document")])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('DateInput',{attrs:{"persistent-hint":"","label":"2 ¿Fecha inicio floraciones? ","hint":"¿Fecha inicio floraciones? ","required":"","min":new Date(Date.now() - 24 * 30 * 24 * 60 * 60 * 1000)
                .toISOString()
                .substr(0, 10),"max":new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)
                .toISOString()
                .substr(0, 10),"rules":[_vm.sharedValidations.requiredValidation]},model:{value:(_vm.formFields.dateOfFlowering),callback:function ($$v) {_vm.$set(_vm.formFields, "dateOfFlowering", $$v)},expression:"formFields.dateOfFlowering"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('DateInput',{attrs:{"persistent-hint":"","label":"3 ¿Fecha estimada de inicio Cosecha? ","hint":"¿Fecha estimada de inicio Cosecha? ","min":new Date(Date.now() - 24 * 30 * 24 * 60 * 60 * 1000)
                .toISOString()
                .substr(0, 10),"max":new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)
                .toISOString()
                .substr(0, 10),"rules":[_vm.sharedValidations.requiredValidation]},model:{value:(_vm.formFields.estimatedDateOfHarvestStart),callback:function ($$v) {_vm.$set(_vm.formFields, "estimatedDateOfHarvestStart", $$v)},expression:"formFields.estimatedDateOfHarvestStart"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-text-field',{attrs:{"persistent-hint":"","label":"4 ¿Mano de Obra estimada para Cosecha (Por Hectárea)?","hint":"¿Mano de Obra estimada para Cosecha (Por Hectárea)?","type":"number","rules":[_vm.sharedValidations.requiredValidation],"required":""},model:{value:(_vm.formFields.estimatedFarmLaborForPicking),callback:function ($$v) {_vm.$set(_vm.formFields, "estimatedFarmLaborForPicking", $$v)},expression:"formFields.estimatedFarmLaborForPicking"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('DateInput',{attrs:{"persistent-hint":"","label":"5 ¿Fecha real de inicio Cosecha?","hint":"¿Fecha real de inicio Cosecha?","rules":[_vm.sharedValidations.requiredValidation],"required":"","min":new Date(Date.now() - 24 * 30 * 24 * 60 * 60 * 1000)
                .toISOString()
                .substr(0, 10),"max":new Date(Date.now() + 90 * 24 * 60 * 60 * 1000)
                .toISOString()
                .substr(0, 10)},model:{value:(_vm.formFields.realDateOfHarvestStart),callback:function ($$v) {_vm.$set(_vm.formFields, "realDateOfHarvestStart", $$v)},expression:"formFields.realDateOfHarvestStart"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }