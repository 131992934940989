<template>
  <v-stepper-content step="6">
    <v-form v-model="valid" ref="form6">
      <v-container fluid>
        <v-row class="blue lighten-5 mb-4">
          <v-col cols="12" sm="4">
            <v-select
              v-model="formFields.district"
              :items="districts"
              :rules="[rules.required]"
              label="District"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4">
            <v-combobox
              v-model="formFields.subCounty"
              :items="subcounties"
              label="Subcounty"
              :rules="[rules.required]"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="4">
            <v-combobox
              v-model="formFields.parish"
              :items="parishes"
              label="Parish"
              :rules="[rules.required]"
            ></v-combobox>
          </v-col>
          <v-col cols="12">
            <v-combobox
              v-model="formFields.village"
              label="Village"
            ></v-combobox>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-btn color="primary" class="mr-3 mb-2 d-xs-block" @click="validateAndContinue" :disabled="!valid">Save & Continue</v-btn>
          <v-btn color="gray darken-3" @click="previous">Back</v-btn>
        </v-row>
      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>
export default {
  name: "FarmLocation",
  props: ["rules", "value", "next", "previous", "customerUuid"],
  data() {
    return {
      valid: false,
      formFields: {},
      geographics: {
        "Kapchorwa": {
          "Central Divison": [],
          "Chema": ['Chema', 'Chemosong', 'Kapkwai', 'Mt Elgon np', 'Munarya', 'Ngasire'],
          "East Division": [],
          "Kapchorwa town council": ['Barawa', 'Kawowo', 'Kokwomurya'],
          "Kaptanga": ['Kapchesombe', 'Kirwoko', 'Kwoti', 'Ngangata', 'Tumboboy'],
          "Kaserem": ['Amukol', 'Marinyi', 'Chebelat', 'Sirimityo', 'Were', 'Kamoko'],
          "Kawowo": ['Cheptuya', 'Kapchella', 'Kobil', 'Kongowo', 'Sanzara', 'Sengwel'],
          "Munaria": [],
          "Sipi": ['Gamatui', 'Mt Elgon np', 'Kabeywa', 'Tangent', 'Yembek', 'Kapkwirwok'],
          "Tegeres": ['Kabat', 'Kutung', 'Tuban', 'Kapenguria', 'Mt Elgon np', 'Kapteret', 'Tegres'],
          "West Division": [],
        },
        "Kween": {
          "Benet": ['Kaseko', 'Kitawoi', 'Kwosir', 'Mt Elgom np', 'Piswa', 'Tuikat', 'Yatui'],
          "Binyiny": ['Kapkoch', 'Kaptoyoy', 'Kono'],
          "Kapnarkut Town Council": [],
          "Kaproron Town Council": ['Cheminy', 'Kamwam', 'Kaptum'],
          "Kaptum": [],
          "Kaseko": [],
          "Kitawoi": [],
          "Kwanyiny": ['Kapkwata', 'Moyok', 'Nyimei'],
          "Kwosir": [],
          "Moyok": [],
          "Ngenge": ['Greek River', 'Kapkwot', 'Sundet'],
          "Tuikat": [],
        },
        "Bukwo": {
          "Amanang": [],
          "Brim": [],
          "Bukwa": ['Amanang', 'Chepkwasta', 'Mt Elgon np', 'Muimet'],
          "Bukwo Town Council": [],
          "Chepkwasta": [],
          "Chesower": ['Kamet', 'Nyalit', 'Tulel'],
          "Kabei": ['Kortek', 'Riwo', 'Mokoyon', 'Mutushe', 'Riwo'],
          "Kamet": [],
          "Kapkworos": [],
          "Kapnandi": [],
          "Kapsarur": [],
          "Kaptererwo": [],
          "Kirwot": [],
          "Kortek": [],
          "Muimet": [],
          "Mutushet": [],
          "Riwo": [],
          "Senendent": [],
          "Suam": ['Kaptererwa', 'Kwirot', 'Senendet'],
          "Tulel": [],
        },
      }
    };
  },
  computed: {
    districts() { return Object.keys(this.geographics).sort(); },
    subcounties() { return (this.formFields.district ? Object.keys(this.geographics[this.formFields.district]).sort() : []); },
    parishes() { return (this.formFields.subCounty && this.geographics[this.formFields.district][this.formFields.subCounty] ? this.geographics[this.formFields.district][this.formFields.subCounty].sort() : []); },
  },
  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.formFields = to;
      },
    },
    formFields(to) {
      this.$emit("input", to);
    },
  },
  methods: {
    validate() {
      this.$refs.form6.validate();
    },
    validateAndContinue() {
      this.validate();
      this.next();
    },
  },
};
</script>

<style>
</style>