<template>
  <v-card>

    <v-card-title>
      {{$t('title')}}
      <v-spacer />
      <v-btn color="primary" small dense @click="edit">{{$t('edit')}}</v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <Row :title="$t('name')" :text="customer.name" bold="true" v-if="customer.name" />
            <Row :title="$t('telephone')" :msisdn="customer.msisdn" />
            <Row :title="$t('partnerIdentifier')" :code="customer.partnerIdentifier" v-if="customer.partnerIdentifier" />
            <Row :title="$t('group')" :text="customer.savingsGroup.name" v-if="customer.savingsGroup" />
            <Row :title="(customer.demographic.primaryIdentityType || customer.demographic.identityType) | id_number_title" :identityNumber="customer.demographic" v-if="identityNumber" />
            <Row :title="$t('tags')" v-if="segmentList.length>0" >
              <v-chip v-for="segment in segmentList" :key="segment" small class="mr-1" >{{ segment }}</v-chip>
            </Row>
            <Row :title="$t('balance')" v-if="show('agri_balance')" >
              <ServerSideResult :test="balance">
                <Currency :value="balance" />
              </ServerSideResult>
            </Row>
            <Row :title="$t('urbanFarmerUnits')" v-if="['urban_zm','urban_ke'].includes(partnerCode)" >
              <ServerSideResult :test="urbanFarmerUnits">
                <NumberFormatter :value="urbanFarmerUnits" unit="units" />
              </ServerSideResult>
            </Row>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>


<script>
  import lookups from '@/mixins/lookups';
  import displaySwitches from '@/mixins/displaySwitches';

  import Row from '@/components/Row';
  import Currency from '@/components/Currency';
  import NumberFormatter from '@/components/NumberFormatter';
  import ServerSideResult from '@/components/ServerSideResult';

  export default {

    components: { Row, Currency, NumberFormatter, ServerSideResult },

    props: ['customer'],
    
    data: () => ({
      balance: undefined,
      urbanFarmerUnits: undefined,
    }),

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      identityNumber() { return (this.customer.demographic ? this.customer.demographic.identityNumber : null); },
      primaryLocation() { return (this.customer.demographic && this.customer.demographic.keyLocations ? this.customer.demographic.keyLocations['region'] || this.customer.demographic.keyLocations['work'] || this.customer.demographic.keyLocations['home'] : null); },
      segmentList() { return this.customer.segmentList || []; },
      fieldAgent() { return (this.customer.fieldAgent ? this.customer.fieldAgent.name : null) },
    },

    watch: { 
      customer: {
        immediate: true,
        handler(to) {
          if (this.show('agri_balance')) {
            this.remoteLookup('customerBalance', { uuid: this.customer.uuid }).then( balance => this.balance = balance ).catch(e => this.balance = null);
          }
          if (['urban_zm','urban_ke'].includes(this.partnerCode)) {
            this.remoteLookup('urbanFarmerPriorUnits', { uuid: this.customer.uuid }).then( priorUnits => this.urbanFarmerUnits = priorUnits ).catch(e => this.urbanFarmerUnits = null);
          }
        },
      },
    },

    methods: {
      edit() { this.$router.push({name: 'EditCustomer', query: { uuid: this.customer.uuid }}); },
    },

    mixins: [lookups, displaySwitches],

  }
</script>


<i18n>
{
  "en": {
    "title": "Details",
    "name": "Name",
    "telephone": "Telephone Number",
    "partnerIdentifier": "Your Identifier",
    "group": "Group",
    "tags": "Tags / Segments",
    "balance": "Balance",
    "urbanFarmerUnits": "Units Purchased"
  },
  "es": {
    "title": "Informacion Personal",
    "name": "Nombre",
    "telephone": "Número de Teléfono",
    "partnerIdentifier": "Tu identificador",
    "group": "Grupo",
    "tags": "Etiquetas / Segmentos",
    "balance": "Saldo",
    "urbanFarmerUnits": "Unidades compradas"
  },
  "sw": {
    "title": "Details",
    "name": "Jina",
    "telephone": "Namba ya Simu",
    "partnerIdentifier": "Kitambulisho",
    "group": "Kikundi",
    "tags": "Lebo / Sehemu",
    "balance": "Usawa",
    "urbanFarmerUnits": "Vitengo Vimenunuliwa"
  }
}
</i18n>
