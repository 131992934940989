export default {
  data: () => ({
    numberRules: [
      v => Number.isInteger(Number(v)) || "Numbers only",
      v => (Number(v) >= 0) || "A positive number please",
      v => !(/\D/.test(v)) || "Numbers please",
    ],
    numberWithDecimalRules: [
      v => (Number(v) >= 0) || "A positive number please",
    ],
  }),
};
