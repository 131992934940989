<template>
  <v-stepper-content step="5" class="ml-0">
    <div class="text-left text-caption" :class="{ 'red--text': !valid }">Note: Data for two seasons are required.</div>
    <v-form v-model="valid" ref="form5">
      <v-simple-table v-if="seasons.length>0">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">
                Year
              </th>
              <th class="text-left">
                Season
              </th>
              <th class="text-left">
                Harvest
              </th>
              <th class="text-left">
                Land
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in seasons" >
              <td>
                <v-icon @click="removeSeason(index)" dense>mdi-delete</v-icon>
                {{item.year}}
              </td>
              <td>
                {{item.season}}
              </td>
              <td>
                <v-text-field
                    suffix="kg"
                  type="number"
                  :min="0"
                  :rules="[rules.required]"
                  v-model.number="item.harvest"
                ></v-text-field>
              </td>
              <td>
                <v-text-field
                  suffix="acres"
                  type="number"
                  :min="0"
                  :rules="[rules.required]"
                  v-model.number="item.land"
                ></v-text-field>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-form>

    <div class="text-left d-flex">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              v-bind="attrs"
              v-on="on"
            >
              Add year
              <v-icon dense>mdi-chevron-down</v-icon>

            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in seasonYears"
              :key="index"
            >
              <v-list-item-title
                  @click="addSeason(item)"
                  role="button"
              >
                {{ item }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
    </div>

    <div class="mt-4 d-flex">
      <v-btn color="primary" class="mr-3 mb-2 d-xs-block" @click="validateAndContinue" :disabled="!areAllRulesValid">Save & Continue</v-btn>
      <v-btn color="gray darken-3" @click="previous">Back</v-btn>
    </div>

  </v-stepper-content>
</template>

<script>
import {getPastFiveYears, summarizeSeasonHarvestTableResults} from "@/views/partners/nile_ug/components/utils";

export default {
  name: "SeasonHarvestTable",
  props: ["value", "next", "previous"],
  data() {
    return {
      seasons: [],
      allYears: getPastFiveYears(),
      selectedYears: null,
      valid: false,
      rules: {
        required: (value) => (value===0 || !!value) || "Required",
      }
    }
  },
  methods: {
    addSeason(selectedYear) {
      const selectedYearResults = this.seasons.filter(formField => formField.year === selectedYear)

      if (selectedYearResults.length >= 2) { return }

      if (!selectedYearResults.filter(formField =>  formField.season === 'B').length) {
        this.seasons.push({
          year: selectedYear,
          season: 'B',
          harvest: 0,
          land: 0
        })
      }

      if (!selectedYearResults.filter(formField =>  formField.season === 'A').length) {
        this.seasons.push({
          year: selectedYear,
          season: 'A',
          harvest: 0,
          land: 0
        })
      }
    },
    removeSeason(itemPosition) {
      const tempFields = Object.assign(this.seasons, [])
      this.seasons = tempFields.filter((item, index) => index !== itemPosition)
    },
    validateAndContinue() {
      this.validate();
      this.next();
    },
    validate() {
      this.$refs.form5.validate();
    },
  },
  computed: {
    seasonYears () { return this.allYears },
    tableResults () { return summarizeSeasonHarvestTableResults(this.seasons) },
    tableResultsValid () {
      // let isValid = true
      // if (!this.tableResults) {
      //   return false
      // }

      // Object.values(this.tableResults).forEach(tableRow => {
      //   if (tableRow.length !== 2) {
      //     isValid = false
      //   }
      // })
      // return isValid
      return true
    },
    areAllRulesValid() {
      return this.valid && this.tableResultsValid
    }
  },
  mounted() {
    // // initialize table data
    // if ((new Date()).getMonth() < 2) {
    //   this.addSeason(this.allYears[1])
    //   this.addSeason(this.allYears[2])
    // } else {
    //   this.addSeason(this.allYears[0])
    //   this.addSeason(this.allYears[1])
    // }
  },
  watch: {
    value: {
      immediate: true,
      handler(to) { this.seasons = to; }
    },
    seasons(to) { this.$emit('input', to); },
  },
}
</script>

<style scoped>

</style>
