<template>

  <v-container fluid class="pa-0">

    <v-snackbar v-model="onlineWarning" timeout="5000" >
      {{$t('searchOnline')}}
      <template v-slot:action="{ attrs }">
        <v-btn color="primary" text v-bind="attrs" @click="onlineWarning = false" >
          {{$t('close')}}
        </v-btn>
      </template>
    </v-snackbar>


    <v-row no-gutters class="my-2">

      <v-col cols="12">
        <v-text-field  v-model="searchText" hide-details :label="$t('typeToSearch')" :placeholder="$t('search')" full-width filled rounded light dense single-line append-icon="mdi-magnify" />
      </v-col>

    </v-row>


    <v-row no-gutters v-if="searchText.length<3">
      <v-col cols="12">
        {{$t('instructions')}}
      </v-col>
    </v-row>

    <template v-if="searchText.length>=3">
      <v-row no-gutters v-if="customers.length===0">
        <v-col cols="12">
          {{$t('noResults')}}
        </v-col>
      </v-row>

      <v-row no-gutters v-else >
        <v-col cols="12">
          <Customers :customers="customers" @show="show" />
        </v-col>
      </v-row>
    </template>

    

  </v-container>
  
</template>


<script>

  import Customers from '@/views/search/components/Index';
  import LoadAndShow from '@/views/customers/components/LoadAndShow';

  export default {

    components: {
      Customers,
      LoadAndShow,
    },

    data: () => ({
      searchText: '',
      onlineWarning: true,
      favouritingInProgress: false,
      showCustomerForPartnerIdentifier: null,
    }),

    computed: {
      customers() { return this.$store.getters.searchResults; },
    },

    watch: {
      searchText: function(s) { this.$store.dispatch('updateOnlineSearch', s); }
    },

    methods: {
      show(customer) {
        this.$router.push({ name: 'ShowCustomer', query: { uuid: customer.uuid } });
      },
    },

  }
</script>


<i18n>
{
  "en": {
    "close": "Close",
    "instructions": "We will start searching once you have entered three characters. You can search for names, telephone numbers, identity numbers and areas.",
    "search": "Search",
    "searchOnline": "Search online for farmers and contacts.",
    "typeToSearch": "Type to search",
    "noResults": "No profiles match your search criteria."
  },
  "es": {
    "close": "Cerrar",
    "instructions": "La búsqueda iniciará una vez ingrese los primeros tres caracteres. Puede buscar nombres, números de teléfono, números de identidad y áreas.",
    "search": "Búsqueda",
    "searchOnline": "Busque agricultores y contactos en línea.",
    "typeToSearch": "Escribe para buscar",
    "noResults": "Ningún perfil coincide con sus criterios de búsqueda."
  },
  "sw": {
    "close": "Funga",
    "instructions": "Tutaanza kutafuta ukishaingiza herufi tatu. Unaweza kutafuta majina, nambari za simu, nambari za kitambulisho na maeneo.",
    "search": "Tafuta",
    "searchOnline": "Tatuta wakulima na mawasiliano.",
    "typeToSearch": "Andika ili utafute",
    "noResults": "Mafuta ya Ningún sanjari na con criterios de búsqueda."
  }
}
</i18n>
