<template>
  <v-simple-table dense>
    <template v-slot:default>
      <tbody>
        <tr v-for="customer in customers" :key="customer.uuid" >
          <td class="text-start">
            {{ customer.name }}
          </td>
          <td class="text-center">
            <v-icon dense small color="green" v-if="customer.nileUgApplication">mdi-check</v-icon>
          </td>
          <td class="text-center">
            <v-btn small color="primary" :to="{name: 'NileRegisterContact', query: { uuid: customer.uuid } }" class="mr-2" >
              <v-icon dense small>mdi-text-account</v-icon>
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>




<script>


  export default {

    props: ['customers'],

    data: () => ({
    }),

    computed: {
      search() { return this.$store.getters.search; },
      // list() {
      //   const regex = new RegExp(this.search, "i");
      //   return this.customers.filter( e => regex.test(e.name) ).sort( (a,b) => { return (a.name < b.name ? -1 : 1); } );
      // },
    },

  }
</script>


<i18n>
{
  "en": {
  },
  "es": {
  },
  "sw": {
  }
}
</i18n>
