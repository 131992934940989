<template>
  <div>
    <PageHeader
      :title="$t('Seedlings & Transplant')"
      :subtitle="customer.name"
      icon="mdi-clipboard-account"
      @back="toCustomer"
      back
      @save="submit"
      save
    />
    <v-form v-model="validForm" ref="seedlingsAndTransplant">
      <SeedlingsRegister :data="formFields" :rules="rules" />
      <SeedlingsSowing :data="formFields" :rules="rules" />

      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-space-between flex-wrap">
              <div class="text-left d-flex mr-1 mb-3">
                <v-btn
                  @click="$emit('clearInputs')"
                  class="d-none d-sm-inline"
                  >{{ $t("Clear") }}</v-btn
                >
              </div>

              <div class="text-right d-flex flex-sm-wrap">
                <v-btn
                  @click="$emit('submitAndEdit')"
                  color="primary"
                  :disabled="!validSubmission"
                  class="mr-2 d-sm-inline-block"
                  >{{ $t("SaveAndEdit") }}</v-btn
                >
                <v-btn
                  @click="$emit('submitAndNew')"
                  color="primary d-sm-inline-block"
                  :disabled="!validSubmission"
                  >{{ $t("SaveAndAdd") }}</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import SeedlingsRegister from "./Phase2Section1Register.vue";
import SeedlingsSowing from "./Phase2Section2Sowing.vue";

export default {
  components: {
    PageHeader,
    SeedlingsRegister,
    SeedlingsSowing,
  },
  data() {
    return {
      validSubmission: false,
      formFields: {
        registerlearningMaterials: [],
        receptionDateOfSeedlings: undefined,
        seedlingsReceived: undefined,
        fromWhichNursery: undefined,
        typeOfTrays: undefined,
        phytoSanitaryStatusOfSeedlings: undefined,
        howManyDaysYouHardenTheSeedlings: undefined,
        areYouReadyForPlanting: undefined,
        registerPhotos: undefined,
        registerlearningMaterials: [],
        dateOfTransplanting: undefined,
        neccessaryToCarryOutPreviousDisinfectionBeforeTranspalnting: undefined,
        didEstablishWellAfterTransplanting: undefined,
        mortalityRate: undefined,
        causeOfIt: undefined,
        whenDidYouReplaceThePlants: undefined,
        sowingPhotos: undefined,
      },
      rules: {
        required: (value) => !!value || "Requerido",
        numberRules: [
          (v) => Number.isInteger(Number(v)) || "Numbers only",
          (v) => Number(v) >= 0 || "A positive number please",
          (v) => !/\D/.test(v) || "Numbers please",
        ],
      },
    };
  },
  computed: {
    customerUuid() {
      return this.$route.query.uuid;
    },
    fieldUuid() {
      return this.$route.query.fieldUuid;
    },
    customer() {
      return this.$store.getters.customer(this.customerUuid);
    },
    partnerCode() {
      return this.$store.getters.settings.partnerCode;
    },

    isStart() {
      return this.step == 1;
    },
    isLastStep() {
      return this.step == 2;
    },
  },
  methods: {
    toCustomer() {
      this.$router.push({
        name: "ShowCustomer",
        query: { uuid: this.customerUuid },
      });
    },

    next() {
      this.step += 1;
    },

    previous() {
      this.step -= 1;
    },

    submit() {},
  },
};
</script>

<style scoped>
</style>

<i18n>
{
  "en": {
    "Field Contract Register": "Field Contract Register",
    "Reception date of seedlings": "Reception date of seedlings",
    "How Many seedlings did you receive?":"How Many seedlings did you receive?",
    "From which Nursery?":"From which Nursery?",
    "Type of Trays?": "Type of Trays?",
    "Phytosanitary status of the seedlings?":"Phytosanitary status of the seedlings?",
    "How Many days you harden the seedlings?":"How Many days you harden the seedlings?",
    "Are you ready for planting?":"Are you ready for planting?",
    "Photos":"Photos",
    "Learning Material":"Learning Material",
    "Date of transplanting?":"Date of transplanting?",
    "It is necessary to carry out previous disinfection before transplanting?.":"It is necessary to carry out previous disinfection before transplanting?.",
    "Did they establish well after transplanting?":"Did they establish well after transplanting?",
    "If NOT, what is the mortality rate?":"If NOT, what is the mortality rate?",
    "What is the cause of it?":"What is the cause of it?",
    "When did you replace the plants?":"When did you replace the plants?"
    

  },
  "es": {
    "Field Contract Register": "CULTIVO CONTRATO",
    "Reception date of seedlings": "¿Fecha recepción plántulas?",
    "How Many seedlings did you receive?":"How Many seedlings did you receive?",
    "From which Nursery?":"F¿Vivero de procedencia?",
    "Type of Trays?": "¿Tipo de Bandejas - Cantidad Alveolos?",
    "Phytosanitary status of the seedlings?":"¿Estado fitosanitario de las plántulas al momento de recepción?",
    "How Many days you harden the seedlings?":"¿Cuanto tiempo aclimató sus plántulas?",
    "Are you ready for planting?":"¿Está listo para transplantar?",
    "Photos":"Foto Plántulas",
    "Learning Material":"Instructivos Transplante",
    "Date of transplanting?":"¿En que fecha sembró?",
    "It is necessary to carry out previous disinfection before transplanting?.":"¿Es necesario realizar desinfección previa antes del trasplante.",
    "Did they establish well after transplanting?":"¿Se establecieron bien las plantas después del transplante?",
    "If NOT, what is the mortality rate?":"NO, ¿cual es la tasa de mortalidad?",
    "What is the cause of it?":"¿Cuál es la causa de la mortalidad?",
    "When did you replace the plants?":"¿Cuando reemplazó las plantas perdidas?"

    
  },
  "sw": {
    "Field Contract Register": "MKATABA WA MAZAO",
    "Reception date of seedlings": "Tarehe ya kuzaliwa",
    "How Many seedlings did you receive?":"How Many seedlings did you receive?",
    "From which Nursery?":"From which Nursery?",
    "Type of Trays?": "Type of Trays?",
    "Phytosanitary status of the seedlings?":"Phytosanitary status of the seedlings?",
    "How Many days you harden the seedlings?":"How Many days you harden the seedlings?",
    "Are you ready for planting?":"Are you ready for planting?",
    "Photos":"Photos",
    "Learning Material":"Learning Material",
    "Date of transplanting?":"Date of transplanting?",
    "It is necessary to carry out previous disinfection before transplanting?.":"It is necessary to carry out previous disinfection before transplanting?.",
    "Did they establish well after transplanting?":"Did they establish well after transplanting?",
    "If NOT, what is the mortality rate?":"If NOT, what is the mortality rate?",
    "What is the cause of it?":"What is the cause of it?",
    "When did you replace the plants?":"When did you replace the plants?"


  }
}
</i18n>