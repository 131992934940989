<template>
  <v-text-field
    v-model="name"
    :rules="[nameRules]"
    :counter="computedCounter"
    :label="$t(label)"
     @input="handleInput"
  ></v-text-field>
</template>

<script>
  export default {
    props: ['value', 'label', 'counter', 'required'],
    props: {
      value: String,
      label: String,
      counter: Number,
      required: Boolean,
    },
    data: () => ({
      name: null,
    }),
    computed: {
      computedCounter() { return this.counter || 30; },
    },
    watch: {
      value: {
        immediate: true,
        handler(to) { this.name = to; }
      },
    },
    methods: {
      handleInput() {
        this.$emit('input', this.name);
      },
      nameRules(value) {
        let r = [
          v => !this.required || !!v || 'Required',
          v => /^.{0,30}$/.test(v) || 'Must be less than 30 characters',
          v => !(/[^\w-\s]/.test(v)) || 'Characters only',
          v => !(/\d/.test(v)) || 'No numbers',
          v => (v || '').length==0 || /^\S/.test(v) || 'No spaces at start',
          v => (v || '').length==0 || /\S$/.test(v) || 'No spaces at end',
        ].map(e => e.call(this, value) ).find(e => typeof(e)==='string');
        return ( typeof(r)==='string' ? r : true );
      },
    },
  };
</script>


<i18n>
{
  "en": {
    "First Name": "First Name",
    "Surname": "Surname"
  },
  "es": {
    "First Name": "Primer nombre",
    "Surname": "Apellido"
  },
  "sw": {
    "First Name": "Jina la Kwanza",
    "Surname": "Jina la Pili"
  }
}
</i18n>
