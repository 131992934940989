<template>
  <v-text-field 
    v-model="searchText"
    v-if="display"
    hide-details
    :label="$t('search')"
    :placeholder="$t('search')"
    filled
    rounded
    light
    dense
    single-line
    append-icon="mdi-magnify"
    style="max-width: 300px;"w
    >
  </v-text-field>
</template>

<script>
export default {
  data () {
    return {
      searchText: '',
    }
  },
  props: ['displaySearchOnRoutes'],
  watch: {
    searchText: function(s) {
      this.$store.dispatch('updateSearch', s);
    }
  },
  computed: {
    display() {
      let showFor = (this.displaySearchOnRoutes || '').split(',').map(r => r.trim());
      return showFor.includes(this.$route.name);
    }
  },
};
</script>


<i18n>
{
  "en": {
    "search": "Search"
  },
  "es": {
    "search": "Búsqueda"
  },
  "sw": {
    "search": "Tafuta"
  }
}
</i18n>
