export default {
  computed: {
    msisdnCountryCode() { return this.$store.getters.settings.countryCode; },
    msisdnTelephoneCode() { return this.$store.getters.settings.telephoneCode; },
    phoneNumberFromMsisdn() {
      const r = new RegExp(`^${this.msisdnTelephoneCode}`);
      return (this.msisdn || '').replace(r, (this.msisdnCountryCode=='CO' ? '' : '0'));
    },
  },
  methods: {
    telephoneToMsisdn(telephone) {
      return this.msisdnTelephoneCode + telephone.replace(/^0/, '');
    },
    phoneNumberRules(v) {
      let r = null;
      if (this.msisdnCountryCode=='CO') {
        r = /^[2345678]\d{9}$/.test(v) || 'El número de teléfono debe tener 10 dígitos y comenzar con 2 a 8'
      } else {
        r = /^0\d{9}$/.test(v) || (this.$store.getters.settings.language=='sw' ? 'Nambari ya simu lazima iwe na tarakimu 10 na uanze na 0' : 'Telephone number must be 10 digits long and start with a 0')
      }
      return r;
    },
  }
};