<template>
  <v-form v-model="validForm" ref="contactForm">
    <v-container>

      <v-row class="blue lighten-5">
        <v-col cols="12" sm="6">
          <MsisdnInput v-model="customer.msisdn" />
        </v-col>
        <v-col cols="12" sm="6">
          <EmailInput v-model="customer.email" />
        </v-col>
      </v-row>

      <v-row class="blue lighten-5">
        <v-col cols="12" sm="6">
          <NameInput v-model="customer.firstName" :label="$t('First Name')" required />
        </v-col>

        <v-col cols="12" sm="6">
          <NameInput v-model="customer.surname" :label="$t('Surname')" required />
        </v-col>
      </v-row>

      <v-row class="red lighten-5">
        <RegionPicker v-model="customer.regions" />
      </v-row>


      <v-row class="green lighten-5">

        <v-col cols="12" sm="6" lg="3">
          <NameInput v-model="customer.farm.name" :label="$t('Farm Name')" required />
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <v-select :items="crops" :label="$t('Primary Crop')" class="text-left" v-model="customer.primaryCrop"></v-select>
        </v-col>

        <v-col cols="12" sm="6" lg="3">
          <v-radio-group v-model="customer.hrCoContactType" row mandatory>
            <v-radio label="Potencial" value="potential"></v-radio>
            <v-radio label="Contratado" value="contracted"></v-radio>
          </v-radio-group>
        </v-col>

      </v-row>

      <v-row>
        <v-col cols="12">
          <IdentityInput v-model="customer.demographic.identity" />
        </v-col>
      </v-row>

      <v-col cols="12" sm="6" lg="3">
        <DateInput
          v-model="customer.visitDate"
          :label="$t('Visit date')"
          :min="new Date().toISOString().substr(0, 10)"
          :max="new Date(Date.now() + 45*24*60*60*1000).toISOString().substr(0, 10)"
        />
      </v-col>



      <v-row class="blue-grey lighten-5">
        <v-col cols="12">
          <v-textarea
            :label="$t('Notes')"
            v-model="customer.note"
            auto-grow
          ></v-textarea>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <div class="d-flex justify-space-between">
            <div class="text-left">
              <v-btn @click="$emit('clearInputs')" class="d-none d-sm-inline">{{$t('Clear')}}</v-btn>
            </div>
            <div class="text-right">
              <v-btn @click="$emit('submitAndEdit')" color="primary" :disabled="!validSubmission" class="mr-2">{{$t('SaveAndEdit')}}</v-btn>
              <v-btn @click="$emit('submitAndNew')" color="primary" :disabled="!validSubmission">{{$t('SaveAndAdd')}}</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>

    </v-container>
  </v-form>
</template>


<script>

  import RegionPicker from '@/components/RegionPicker';
  import MsisdnInput from '@/components/MsisdnInput';
  import EmailInput from '@/components/EmailInput';
  import NameInput from '@/components/NameInput';
  import DateInput from '@/components/DateInput';
  import IdentityInput from '@/components/IdentityInput';

  export default {

    components: {
      RegionPicker,
      MsisdnInput,
      EmailInput,
      NameInput,
      DateInput,
      IdentityInput,
    },

    props: ['customer'],

    data: () => ({
      validForm: false,
    }),


    computed: {
      crops() { return this.$store.getters.cropNames; },
      validSubmission() {
        let r = this.validForm;
        r = r && (this.customer.regions || []).filter(e => !!e).length==4
        return r;
      }
    },

  }
</script>


<i18n>
{
  "en": {
    "Primary Crop": "Primary Crop",
    "First Name": "First Name",
    "Surname": "Surname",
    "Notes": "Notes",
    "Visit date": "Next visit date",
    "SaveAndEdit": "Save & Edit",
    "SaveAndAdd": "Save & Add",
    "Clear": "Clear",
    "Farm Name": "Farm Name"
  },
  "es": {
    "Primary Crop": "Variedad",
    "First Name": "Primer nombre",
    "Surname": "Apellido",
    "Notes": "Notas",
    "Visit date": "Fecha de la próxima visita",
    "SaveAndEdit": "Salvar o Editar",
    "SaveAndAdd": "Salvar y añadir (Otro Contacto)",
    "Clear": "Reiniciar",
    "Farm Name": "Finca"
  },
  "sw": {
    "Primary Crop": "Mazao ya Msingi",
    "First Name": "Jina la kwanza",
    "Surname": "Jina la jina",
    "Notes": "Noti",
    "Visit date": "Tarehe inayofuata ya ziara",
    "SaveAndEdit": "Hifadhi na hariri",
    "SaveAndAdd": "Hifadhi na sajili mwingine",
    "Clear": "Futa",
    "Farm Name": "Jina la Shamba"
  }
}
</i18n>
