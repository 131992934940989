<template>
  <PageHeader title="Por Aprobar" :subtitle="customer.name" icon="mdi-flower-outline" @back="toCustomer" @save="submit" back save :valid='formValid' >

<!--    <Pests v-model="pestsAndDiseaseRecommendations" />-->
<!--        <v-row align="center">-->
<!--      <v-col-->
<!--        class="d-flex"-->
<!--        cols="12"-->
<!--        sm="6"-->
<!--      >-->
<!--        <v-select-->
<!--          :items="fields"-->
<!--          item-text="name"-->
<!--          label="Select Field"-->
<!--          v-model="selectedFarmField"-->
<!--        ></v-select>-->
<!--      </v-col>-->
<!--        </v-row>-->

<!--          <hr class="mb-4" />-->

    <v-form>

      <FormContainer color="blue lighten-5 text-left pa-2">

          <div class="row">
            <div cols="12" class="col-sm-4">
              <v-card  tile>
                <v-card-title>{{ customer.name }}</v-card-title>
              </v-card>
            </div>
          </div>

          <div class="row">

            <div cols="12" class="col-sm-4">
              <v-card-subtitle>1. {{ $t("registerForm.form.fieldPhoto") }}</v-card-subtitle>
              <v-card-text>
                <SingleImage :image="profileImage" :maxHeight="150" />
                <TakePhoto @click="saveImage">
                  <v-btn color="primary" small dense class="ml-1"
                    ><v-icon dense class="mr-1">mdi-camera-outline</v-icon>
                    <span class="hidden-sm-and-down">{{
                      $t("photo")
                    }}</span></v-btn
                  >
                </TakePhoto>
              </v-card-text>
            </div>

            <div cols="12" class="col-sm-4">
              <v-card-subtitle>2. {{$t("registerForm.form.fieldGps") }}</v-card-subtitle>
              <v-card-text>
                <LocationInput v-model="assessment.farmGPS" />
              </v-card-text>
            </div>

            <div cols="12" class="col-sm-4">
              <v-card-subtitle>3. {{$t("registerForm.form.areaOfFarm") }}</v-card-subtitle>
              <v-card-text>
                <LandSizeInput v-model="assessment.landSize" />
              </v-card-text>
              </div>
            </div>

          </div>

          <hr class="mb-4" />

          <v-row>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['ubicacionTerreno']"          title="Ubicación terreno"           description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['alturaSobreNivelDelMar']"    title="Altura sobre nivel del mar"  description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['zonaSeca']"                  title="Zona seca"                   description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['disponibilidadDeAguas']"     title="Disponibilidad de aguas"     description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['analisisDeSuelos']"          title="Análisis de suelos"          description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['tierrasPropias']"            title="Tierras propias"             description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['riegosPorGoteo']"            title="Riegos por goteo"            description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['manoDeObraParaCosecha']"     title="Mano de obra para cosecha"   description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['agricultorConExperiencia']"  title="Agricultor con experiencia"  description="TBD" /></v-col>
            <v-col cols="12" sm="4"><StarRatingCard v-model="assessment['recursosEconomicos']"        title="Recursos económicos"         description="TBD" /></v-col>

            <v-col cols="12">
              <v-textarea
                label="Observaciones"
                v-model="assessment.Observations"
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>

      </FormContainer>
    </v-form>
  </PageHeader>
</template>

<style scoped>

</style>
<script>
import { v4 as uuidv4 } from "uuid";

import PageHeader from "@/components/PageHeader";
import FormContainer from "@/components/FormContainer";
import StarRatingCard from "@/components/StarRatingCard";
import TakePhoto from "@/components/TakePhoto";
import SingleImage from "@/components/SingleImage";
import LocationInput from "@/components/LocationInput";
import LandSizeInput from "@/components/LandSizeInput";
import Pests from "@/views/partners/hr_co/components/Pests";
import Row from "@/components/Row";

export default {

  components: { LandSizeInput, PageHeader, FormContainer, StarRatingCard, TakePhoto, SingleImage, LocationInput, Pests, Row },

  data: () => ({
    assessment: {
      uuid: uuidv4(),
      Observations: "",
      visitDateInput: new Date(),
      farmGPS: undefined,
      landSize: undefined,
    },
    pestsAndDiseaseRecommendations: [],
    selectedFarmField: null
  }),

  computed: {
    customerUuid() { return this.$route.query.uuid; },
    fields() {
      console.log('fields', this.customer.fields)
      return (this.customer.fields || []).filter(field => field.deleted===undefined ) ;
      },
    customer() { return this.$store.getters.customer(this.customerUuid); },
    formValid() { return Object.keys(this.assessment).length==15 }, // we already have two elements in the set: Observations and uuid
    profileImage() {
      let image = undefined;
      // these probably are more recent than anything already uploaded
      let images = this.$store.getters.images.filter(
        (e) => e.type_id === this.customer.uuid && e.type === "customer"
      );
      if (images.length > 0) {
        image = images[images.length - 1];
      }
      if (
        image == null &&
        this.customer.imageUrls &&
        this.customer.imageUrls.length > 0
      ) {
        image = this.customer.imageUrls[this.customer.imageUrls.length - 1];
      }
      return image;
    },
  },

  watch: {
    customer: {
      immediate: true,
      handler(to) {
        if (to) {
          this.assessment.farmGPS = (((to.demographic || {}).keyLocations || {}).work || {}).lookupParameters;
          this.assessment.landSize = to.landSize;
        } else {
          this.assessment.farmGPS = undefined;
          this.assessment.landSize = undefined;
        }
      },
    },
  },

  methods: {
    saveImage(uuid) {
      this.$store.dispatch("addImage", {
        type: "customer",
        type_id: this.customer.uuid,
        uuid: uuid,
      });
    },

    submit() {
      let score = 0;
      const results = this.assessment;

      score = score + results['ubicacionTerreno'] *0.13;
      score = score + results['alturaSobreNivelDelMar'] *0.08;
      score = score + results['zonaSeca'] *0.1;
      score = score + results['disponibilidadDeAguas'] *0.12;
      score = score + results['analisisDeSuelos'] *0.12;
      score = score + results['tierrasPropias'] *0.05;
      score = score + results['riegosPorGoteo'] *0.13;
      score = score + results['manoDeObraParaCosecha'] *0.09;
      score = score + results['agricultorConExperiencia'] *0.09;
      score = score + results['recursosEconomicos'] *0.09;

      score = Math.round(score);
    },

    toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },

  },
};
</script>

<i18n>
{
  "en": {
    "registerForm":  {
      "title": "Register Section",
      "form": {
        "visitDate": "Visit Date",
        "fieldPhoto": "Field Photo",
        "fieldGps": "Field Gps",
        "areaOfFarm": "Area of Farm"
      }
    }
  },
  "es": {
    "registerForm":  {
      "title": "Sección de registro",
      "form": {
        "visitDate": "Visit Date",
        "fieldPhoto": "Field Photo",
        "fieldGps": "Field Gps",
        "areaOfFarm": "Area of Farm"
      }
    }
  },
  "sw": {
    "registerForm":  {
      "title": "Sehemu ya Usajili",
      "form": {
        "visitDate": "Visit Date",
        "fieldPhoto": "Field Photo",
        "fieldGps": "Field Gps",
        "areaOfFarm": "Area of Farm"
      }
    }
  }
}
</i18n>
