<template>
<v-form v-model="validForm" ref="fieldContractRegisterForm">
   <PageHeader :title="$t('Field Contract Register')" :subtitle="customer.name" icon="mdi-clipboard-account" @back="toCustomer" back @save="submit" save >


      <v-container fluid>

        <v-row class="yellow lighten-5">

          <v-col cols="12" sm="3">
            <DateInput
              v-model="customer.demographic.birthdate"
              :label="$t('Date of Birth')"
              min="1910-01-01"
              :max="new Date().toISOString().substr(0, 10)"
              year="true"
            />
          </v-col>

          <v-col cols="12" sm="2">
            <v-select
              v-model="customer.demographic.dependents"
              :label="$t('Dependents')"
              :items="[0,1,2,3,4,5,6,7,8]"
            ></v-select>
          </v-col>

          <v-col cols="12" sm="4">
            <GenderInput v-model="customer.demographic.gender" />
          </v-col>

          <v-col cols="12" sm="3" >
            <v-select
              v-model="customer.demographic.schooling"
              :label="$t('Level of education')"
              :items="schoolingOptions"
            ></v-select>
          </v-col>

        </v-row>

        <v-row class="red lighten-5">
           <v-col cols="12">
            <v-text-field type="text" :label="$t('Home Address')"  v-model="farmAddress" ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="red lighten-5">
          <RegionPicker v-model="regions" />
        </v-row>
    
        <v-row class="blue lighten-5">

          <v-col cols="12" sm="6" lg="3">
            <DateInput
              v-model="contractDate"
              :label="$t('H.R Contract Date')"
              :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
              :max="new Date(Date.now() +    90*24*60*60*1000).toISOString().substr(0, 10)"
            />
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-text-field type="text" :label="$t('Number of Contract')"  required></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-text-field :label="$t('H.R Field Code')" v-model="fieldCode" :rules="[fieldCodeRules]" />
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-text-field :label="$t('H.R Previous Fields Code')" v-model="priorFieldCode" :rules="[priorFeldCodeRules]" />
          </v-col>

        </v-row>


        <v-row class="green lighten-5">

          <v-col cols="12" sm="6" lg="3">
            <LandSizeInput :label="$t('Field Area')" v-model="fieldSize" />
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-text-field :label="$t('Total Number of Plants')" v-model="numberOfPlants" type="number" :rules="numberRules" min=0 ></v-text-field>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-select :items="seedOrigins" :label="$t('Seeds origin')" class="text-left mt-3" v-model="seedOrigin"  :rules="seedRules"></v-select>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <v-select :items="harvestSpecifications" :label="$t('Harvest specifications')" class="text-left" v-model="harvestSpecification" multiple chips :rules="harvestRules"></v-select>
          </v-col>

          <v-col cols="12" sm="6" lg="6" class="text-left">
            <label>{{$t('Harvest Implements')}}</label>
            <v-radio-group row mandatory>
              <v-radio label="Si" value="yes"></v-radio>
              <v-radio label="Non" value="no"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6" lg="3">
            <DepotInput v-model="depot" required />
          </v-col>

        </v-row>

        <v-row class="grey lighten-5">

          <v-col cols="12" sm="4" lg="4">
           <DateInput
                v-model="targetDateSeedsPlanted"
                :label="$t('Target Date for seeds planting')"
                :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
                :max="new Date(Date.now() +   360*24*60*60*1000).toISOString().substr(0, 10)"
              />
          </v-col>
          <v-col cols="12" sm="4" lg="4">
           <DateInput
                v-model="targetDateSeedlingsPlanted"
                :label="$t('Target Date for seedlings planting: DDT')"
                :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
                :max="new Date(Date.now() +   360*24*60*60*1000).toISOString().substr(0, 10)"
              />
          </v-col>
          <v-col cols="12" sm="4" lg="4">
           <DateInput
                v-model="targetDateHarvest"
                :label="$t('Date of starting harvest')"
                :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
                :max="new Date(Date.now() +   360*24*60*60*1000).toISOString().substr(0, 10)"
              />
          </v-col>

        </v-row>

        <v-row class="orange lighten-5">
          <v-col cols="12" sm="6" lg="3">
            <v-text-field :label="$t('Maximun estimated production')" v-model="maximumEstimatedProduction" type="number" :rules="numberRules" min=0 suffix="kg" ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field :label="$t('Minimum estimated Production')" v-model="minimumEstimatedProduction" type="number" :rules="numberRules" min=0 suffix="kg" ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="pink lighten-5">
          <v-col cols="12" sm="6" md="3">
            <DateInput
              v-model="contractEndDate"
              :label="$t('Date of Contract ending')"
              :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
              :max="new Date(Date.now() +    90*24*60*60*1000).toISOString().substr(0, 10)"
            />
          </v-col>
         
          <v-col cols="12" sm="2" md="1">
            <v-switch v-model="cop" flat :label="currency"></v-switch>
          </v-col>

          <v-col cols="12" sm="4" md="4">
            <v-text-field
              v-model="contractPrice"
              :rules="[priceRules]"
              :label="$t('Pesos/U$ per Kilo')"
              min=0
              type='number'
              :prefix="currency"
              suffix="/kg"
              @input='setCurrency()'
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
              v-model="valueContract"
              :rules="[priceRules]"
              :label="$t('Total Value of Contract')"
              min=0
              type='number'
              :prefix="currency"
              @input='setCurrency()'
            />
          </v-col>
        </v-row>

        <v-row class="blue lighten-5">
          <v-col cols="12" sm="4">
            <v-text-field
              v-model="goodProductionBonus"
              :rules="[priceRules]"
              :label="$t('Extra Payment Good Production')"
              min=0
              type='number'
              :prefix="currency"
              suffix="/kg"
              @input='setCurrency()'
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
              v-model="transportPayment"
              :rules="[priceRules]"
              :label="$t('Extra Payment for Transport')"
              min=0
              type='number'
              :prefix="currency"
              suffix="/kg"
              @input='setCurrency()'
            />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field
              v-model="handLabourPayment"
              :rules="[priceRules]"
              :label="$t('Extra Payment for Hand Labor')"
              min=0
              type='number'
              :prefix="currency"
              suffix="/kg"
              @input='setCurrency()'
            />
          </v-col>

        </v-row>

      </v-container>
    </PageHeader>
  </v-form>
  
</template>

<script>
 import PageHeader from '@/components/PageHeader';
 import FormContainer from '@/components/FormContainer';
 import GenderInput from "@/components/GenderInput"
 import DateInput from '@/components/DateInput';
 import NameInput from '@/components/NameInput';
 import RegionPicker from '@/components/RegionPicker';
 import LandSizeInput from '@/components/LandSizeInput';
 import DepotInput from '@/components/DepotInput';
 import Row from '@/components/Row';

export default {

    components: { PageHeader, FormContainer, DateInput,NameInput,RegionPicker,LandSizeInput,GenderInput,Row,DepotInput },

    data: () => ({
      validForm: true,
      regions: [],
      farmAddress: undefined,

      fieldSize: undefined,
      numberOfPlants: undefined,
      contractDate: undefined,
      fieldCode: undefined,
      priorFieldCode: undefined,
      harvestSpecification: undefined,
      seedOrigin: undefined,
      depot: undefined,
      targetDateSeedsPlanted: undefined,
      targetDateSeedlingsPlanted: undefined,
      targetDateHarvest: undefined,
      targetDateSeedsPlanted: undefined,
      targetDateSeedlingsPlanted: undefined,
      targetDateHarvest: undefined,
      contractEndDate: undefined,
      maximumEstimatedProduction: undefined,
      minimumEstimatedProduction: undefined,
      contractCurrency: undefined,
      contractPrice: undefined,
      valueContract: undefined,
      goodProductionBonus: undefined,
      transportPayment: undefined,
      handLabourPayment: undefined,

      cop: false,
      harvestSpecifications: ['Verde', 'Roja', 'Amarilla'],
      seedOrigins: ['Hugo Restrepo', 'Otro'],

      numberRules: [
        v => Number.isInteger(Number(v)) || 'Numbers only',
        v => (Number(v) >= 0) || 'A positive number please',
        v => !(/\D/.test(v)) || 'Numbers please',
      ],
      cropRules: [ v => !!v || "Seleccione un cultivo" ],
      harvestRules: [ v => v.length>0 || 'Especifique el tipo de cosecha' ],
      seedRules: [ v => !!v || 'Confirme la fuente de la semilla' ],
    }),

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      demographic() { return this.customer.demographic || {} },
      schoolingOptions() { return [0,1,2,3,4,5,6,7,8,9,10,11,12]; },
      currency() { return ( this.cop ? 'COP' : '$' ); },
    },
    methods: {
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
      setCurrency() { this.contractCurrency = (this.currency==='COP' ? 'COP' : 'USD'); },
      priceRules(value) {
        let r = (parseFloat(value) >= 0);
        if (!r) { r = 'Ingrese una cantidad positiva';}
        return r;
      },
      fieldCodeRules(value) {
        return /^\d{3}[a-zA-Z]$/.test(value) || 'Código de campo no válido';
      },
      priorFeldCodeRules(value){
        return (value || '').length==0 || /^\d{3}[a-zA-Z]$/.test(value) || 'Código de campo no válido';
      },
      submit() {
      },
    },

    watch: {
      customer: {
        immediate: true,
        handler(to) {
          if (to) {
            this.farmAddress = ((((to.demographic || {}).keyLocations || {}).work || {}).lookupParameters || {}).address;
            this.regions = (to.demographic || {}).geographicsArray;
          }
        },
      },
    },

}
</script>

<i18n>
{
  "en": {
    "Field Contract Register": "Field Contract Register",
    "Date of Birth": "Date of Birth",
    "Dependents": "Dependents",
    "Sex": "Sex",
    "Level of education": "Level of education",
    "Home Address": "Home Address",
    "H.R Contract Date": "H.R Contract Date",
    "Number of Contract": "Number of Contract",
    "H.R Field Code": "H.R Field Code",
    "H.R Previous Fields Code": "H.R Previous Fields Code",
    "Field Area": "Field Area",
    "Total Number of Plants": "Total Number of Plants",
    "Seeds origin": "Seeds origin",
    "Hybrids": "Hybrids",
    "Harvest specifications": "Harvest specifications",
    "Harvest Implements": "Harvest Implements",
    "Yes": "Yes",
    "No": "No",
    "Grinding Station": "Grinding Station",
    "Target Date for seeds planting": "Target Date for seeds planting",
    "Target Date for seedlings planting: DDT": "Target Date for seedlings planting: DDT",
    "Date of starting harvest": "Date of starting harvest",
    "Maximun estimated production": "Maximun estimated production",
    "Date of Contract ending": "Date of Contract ending",
    "Pesos/U$ per Kilo": "Pesos/U$ per Kilo",
    "Total Value of Contract": "Total Value of Contract",
    "Extra Payment Good Production": "Extra Payment Good Production",
    "Extra Payment for Transport": "Extra Payment for Transport",
    "Extra Payment for Hand Labor": "Extra Payment for Hand Labor",
    "SaveAndEdit": "Save & Edit",
    "SaveAndAdd": "Save & Add",
    "Clear": "Clear"

  },
  "es": {
    "Field Contract Register": "Cultivo Contrato",
    "Date of Birth": "Fecha de Nacimiento",
    "Dependents": "Personas a Cargo en Familia",
    "Sex": "Sexo",
    "Level of education": "Años de Escolaridad",
    "Home Address": "Dirección Residencia",
    "H.R Contract Date": "Fecha Contrato H.R",
    "Number of Contract": "Número de Contrato Asignado",
    "H.R Field Code": "Código Cultivo H.R asignado",
    "H.R Previous Fields Code": "Códigos Cultivos H.R anteriores",
    "Field Area": "Area Lote (Hectáreas)",
    "Total Number of Plants": "Total PLantas",
    "Seeds origin": "Origen Semilla",
    "Hybrids": "Híbridos",
    "Harvest specifications": "Especificaciones de cosecha",
    "Harvest Implements": "Usa Canastillas en la Finca para otros cultivos?",
    "Yes": "Si",
    "No": "No",
    "Grinding Station": "Estación de Molienda asignada",
    "Target Date for seeds planting": "Fecha límite de siembra en vivero",
    "Target Date for seedlings planting: DDT": "Fecha estimada de Transplante: DDT",
    "Date of starting harvest": "Fecha de Inicio Producción",
    "Maximun estimated production": "Producción máxima estimada Totales",
    "Minimum estimated Production": "Minimum estimated Production",
    "Minimum estimated Production": "Producción mínima en Kilos",
    "Date of Contract ending": "Fecha Terminación Contrato",
    "Pesos/U$ per Kilo": "Pesos por KIlo a pagar (U$ o Pesos)",
    "Total Value of Contract": "Valor total del contrato",
    "Extra Payment Good Production": "Extra Payment Good Production",
    "Extra Payment for Transport": "Auxilio de Transporte KIlo. (U$ o Pesos)",
    "Extra Payment for Hand Labor": "Auxilio mano de obra por cosecha kilo (U$ o Pesos)",
    "SaveAndEdit": "Salvar o Editar",
    "SaveAndAdd": "Salvar y añadir (Otro Contacto)",
    "Clear": "Reiniciar"
    
  },
  "sw": {
    "Field Contract Register": "Mkataba Wa Mazao",
    "Date of Birth": "Tarehe ya kuzaliwa",
    "Dependents": "Wategemezi",
    "Sex": "jinsia",
    "Level of education": "Miaka ya Masomo",
    "Home Address": "Anwani ya Makazi",
    "H.R Contract Date": "Tarehe ya Mkataba H.R",
    "Number of Contract": "Nambari ya Mkataba Uliokabidhiwa",
    "H.R Field Code": "Msimbo wa Sehemu H.R amepewa",
    "H.R Previous Fields Code": "Msimbo wa Sehemu Zilizopita za H.R",
    "Field Area": "Eneo la Mengi (Hekta)",
    "Total Number of Plants": "Jumla ya Mimea",
    "Seeds origin": "Asili ya Mbegu",
    "Hybrids": "Mseto",
    "Harvest specifications": "Maelezo ya Mavuno",
    "Harvest Implements": "Je, unatumia Vikapu shambani kwa mazao mengine?",
    "Yes": "Ndio",
    "No": "La",
    "Grinding Station": "Kituo Kilichokabidhiwa cha Kusaga",
    "Target Date for seeds planting": "Tarehe inayolengwa ya kupanda mbegu",
    "Target Date for seedlings planting: DDT": "Tarehe ya Kupandikiza Iliyokadiriwa: DDT",
    "Date of starting harvest": "Tarehe ya Kuanza Uzalishaji",
    "Maximun estimated production": "Uzalishaji Unaokadiriwa wa Juu - Jumla ya Kilo",
    "Minimum estimated Production": "Kiwango cha chini cha uzalishaji katika Kilo",
    "Date of Contract ending": "Tarehe ya Kusitishwa kwa Mkataba",
    "Pesos/U$ per Kilo": "Pesa kwa kila kilo ya kulipa (U$ au Pesos)",
    "Total Value of Contract": "Jumla ya thamani ya mkataba",
    "Extra Payment Good Production": "Malipo ya Ziada Uzalishaji Mzuri kwa Kilo (U$ au Pesos)",
    "Extra Payment for Transport": "Malipo ya Ziada ya Usafiri kwa Kilo (U$ au Pesos)",
    "Extra Payment for Hand Labor": "Malipo ya Ziada ya Kazi ya Mikono kwa kilo (U $ au Pesos))",
    "SaveAndEdit": "Hifadhi na hariri",
    "SaveAndAdd": "Hifadhi na sajili mwingine",
    "Clear": "Futa"

  }
}
</i18n>