<template>
  <div>
    <div class="mb-2" align="center">
      <Logo :maxWidth="250" :maxHeight="250" />
    </div>
    <div align="center">
      <code v-if="prod">{{version}}</code>
      <code v-else>Development</code>
    </div>
  </div>
</template>

<script>
import Logo from '@/components/Logo';

export default {
  components: { Logo },
  computed: {
    partnerCode() { return this.$store.getters.settings.partnerCode; },
    version() { return this.$store.getters.appVersion; },
    prod() { return (process.env.NODE_ENV === 'production'); },
    language() { return this.$store.getters.settings.language; },
  },
  mounted() { this.$i18n.locale = this.language; }
};
</script>
