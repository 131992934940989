<template>

  <v-data-table dense :headers="headers" :items="farmers" item-key="item.uuid" :search="search" >

    <template v-slot:header.firstName>{{$t('firstName')}}</template>
    <template v-slot:header.surname>{{$t('surname')}}</template>
    <!-- <template v-slot:header.partnerIdentifier>{{$t('partnerIdentifier')}}</template> -->
    <template v-slot:header.msisdn>{{$t('telephone')}}</template>
    <template v-slot:header.identity_number>{{$t('idNumber')}}</template>


    <template v-slot:item.id="{ item }">
      <v-btn small color="primary" @click="$emit('show', item)" >
        <v-icon>mdi-account</v-icon>
      </v-btn>
    </template>

<!--     <template v-slot:item.partnerIdentifier="{ item }">
      <PartnerIdentifier v-if="item.partnerIdentifier" :identifier="item.partnerIdentifier" />
    </template> -->

    <template v-slot:item.msisdn="{ item }">
      <Telephone :msisdn="item.msisdn" />
    </template>

    <template v-slot:item.identity_number="{ item }">
      <IdentityNumber :demographic="item.demographic" />
    </template>

    <template v-slot:item.id="{ item }">
      <v-btn small color="primary" :to="{name: 'ShowCustomer', query: { uuid: item.uuid } }" class="mr-2" >
        <v-icon dense small>mdi-account</v-icon>
      </v-btn>
    </template>

  </v-data-table>

</template>


<script>


  import IdentityNumber from '@/components/IdentityNumber';
  import Telephone from '@/components/Telephone';
  // import PartnerIdentifier from '@/components/PartnerIdentifier';

  export default {

    components: { IdentityNumber, Telephone },

    props: ['farmers'],

    data: () => ({
      headers: [
        {
          align: 'start',
          sortable: true,
          value: 'firstName',
        },
        {
          align: 'start',
          sortable: true,
          value: 'surname',
        },
        // {
        //   align: 'start',
        //   sortable: true,
        //   value: 'partnerIdentifier',
        // },
        {
          align: 'start',
          sortable: true,
          value: 'msisdn',
        },
        {
          align: 'start',
          sortable: true,
          value: 'identity_number',
        },
        {
          align: 'right',
          value: 'id',
        },
      ]

    }),

    computed: {
      search() { return this.$store.getters.search; },
      partnerCode() { return this.$store.getters.settings.partnerCode; },
    },

  }
</script>


<i18n>
{
  "en": {
  },
  "es": {
  },
  "sw": {
  }
}
</i18n>
