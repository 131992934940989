<template>
  <v-container fluid class="pa-0" v-if="formFields">
    <v-row>
      <v-col cols="12">
        <v-text-field
          suffix="acres"
          type="number"
          :min="0"
          :rules="numberWithDecimalRules"
          v-model.number="formFields.acerage"
          label="Acerage"
          hint="Total acerage to be planted"
          @input="updateAcerage()"
        ></v-text-field>
      </v-col>

      <v-col cols="12" class="pa-0">
        <v-data-table
          dense
          :headers="headers"
          :items="formFields.tableResults"
          item-key="item.uuid"
          :items-per-page="-1"
          class="w-100"
          hide-default-footer
        >
          <template v-slot:header.description>{{ $t("Description") }}</template>
          <template v-slot:header.price_per_unit>{{ $t("Price") }} <small>/ {{ $t("unit") }}</small></template>
          <template v-slot:header.quantity>{{ $t("Quantity") }}</template>
          <template v-slot:header.cost>{{ $t("Cost") }}</template>

          <template v-slot:item.price_per_unit="{ item }">
            <Currency :value="item.price_per_unit" />
            <span class="hidden-xs-only pl-0"><small>/ {{ item.unit }}</small></span>
          </template>

          <template v-slot:item.quantity="{ item }">
            <v-text-field
              min="0"
              type="number"
              :rules="(item.unit === 'l' ? numberWithDecimalRules : numberRules)"
              style="text-align: right"
              v-model="item.quantity"
              @input="updateCost(item.uuid)"
            />
          </template>

          <template v-slot:item.cost="{ item }" class="hidden-xs-only">
            <Currency :value="Math.round(parseFloat(item.quantity) * item.price_per_unit)" />
          </template>
        </v-data-table>
      </v-col>

      <v-col cols="12 d-flex justify-space-between" class="font-weight-bold">
        <div></div>
        <div>
          Total
          <Currency :value="cost" />
        </div>
      </v-col>
    </v-row>

  </v-container>
</template>


<script>
import { v4 as uuidv4 } from "uuid";

import validationRules from "@/mixins/validationRules";

import InputOrderEdit from "@/views/inputOrders/components/InputOrderEdit";
import Currency from "@/components/Currency";
import NumberFormatter from "@/components/NumberFormatter";

export default {

  name: "NileFarmerNeeds",

  components: { InputOrderEdit, Currency, NumberFormatter },

  props: ['value'],

  data: () => ({
    formFields: undefined,
    defaults: {
      buctril: 0.5,
      can: 50,
      dap: 50,
      folicur: 0.3,
      hesekwa: 40,
      huskie: 0.4,
      nativo: 0,
      thunder: 0.12,
    },
  }),

  computed: {
    currency() { return this.$store.getters.currency; },
    cost() { return this.formFields.tableResults.map( e => (parseFloat(e.quantity) || 0) * parseFloat(e.price_per_unit) ).reduce((a, b) => a + b, 0); },
    formValid() { return this.cost >= 0; },
    skus() { return this.$store.getters.settings.skus || []; },
    headers() {
      return [
        {
          align: "start",
          sortable: true,
          value: "description",
        },
        {
          align: "center",
          sortable: true,
          value: "price_per_unit",
        },
        {
          align: "start",
          sortable: false,
          value: "quantity",
        },
        {
          align: "end",
          sortable: false,
          value: "cost",
        },
      ];
    },

  },

  watch: {

    value: {
      immediate: true,
      handler(to) {
        this.formFields = to || {};
        this.formFields.tableResults = this.formFields.tableResults || [];
        let skus = this.$store.getters.settings.skus;
        (skus || []).forEach(e => {
          let f = this.formFields.tableResults.find( g => (g.code===e.code) );
          if (f) {
            f.quantity = f.quantity || 0;
            f.cost = f.cost || 0;
          } else {
            this.formFields.tableResults.push({
              price_per_unit: e.price_per_unit,
              code: e.code,
              description: e.description,
              unit: e.unit,
              quantity: 0,
              cost: 0,
              uuid: uuidv4(),
            });
          }
        });
      },
    },
    formFields(to) { this.$emit("input", to); },
  },

  methods: {
    updateAcerage() {
      let area = parseFloat(this.formFields.acerage);
      if (area<0) { area = 0; }
      this.skus.forEach(e => {
        let f = this.formFields.tableResults.find( g => (g.code===e.code) );
        if (f) {
          let need = this.defaults[f.code];
          f.quantity = area * (need || 0);
          if (f.unit==='l') {
            f.quantity = Math.round(f.quantity*10)/10;
          } else {
            f.quantity = Math.round(f.quantity);
          }
          f.cost = f.quantity * f.price_per_unit;
        }
      });
      this.formFields.total = this.cost;
    },
    updateCost(uuid) {
      let index = this.formFields.tableResults.findIndex((e) => e.uuid === uuid);
      let item = this.formFields.tableResults[index];
      if ( item.unit === 'l') {
        item.quantity = parseFloat(item.quantity || 0);
      } else {
        item.quantity = parseInt(item.quantity || 0);
      }
      if (item.quantity<0) { item.quantity = 0; }
      item.cost = item.quantity * item.price_per_unit;
      this.formFields.total = this.cost;
    },
  },

  mixins: [validationRules],
};
</script>

<style scoped>
</style>