<template>
  <v-text-field
    v-model="telephone"
    :rules="[phoneNumberRules]"
    :counter="10"
    :label="$t('telephone_number')"
    required
     @input="handleInput"
  ></v-text-field>
</template>

<script>
import msisdnToTelephone from '@/mixins/msisdnToTelephone'

export default {
  props: ['value'],
  data: () => ({
    telephone: null,
  }),
  computed: {
    msisdn() { return this.value; },
  },
  watch: {
    phoneNumberFromMsisdn: {
      immediate: true,
      handler(to) { this.telephone = to; }
    },
  },
  methods: {
    handleInput() {
      const m = this.telephoneToMsisdn(this.telephone);
      this.$emit('input', m);
    },
  },
  mixins: [msisdnToTelephone],
};
</script>


<i18n>
{
  "en": {
    "telephone_number": "Telephone Number"
  },
  "es": {
    "telephone_number": "Número de Teléfono"
  },
  "sw": {
    "telephone_number": "Namba ya Simu"
  }
}
</i18n>
