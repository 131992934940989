<!-- 
   bold
  humanize

   unit

   number
    text
  msisdn
  volume
  badges
  date
  time
  ago
    code
  status
  amount
  balance
 -->

<template>
  <tr>
    <td :class="classes">{{title}}</td>
    <td :class="classes">
      <slot>
        <span v-if="text">{{ text }}</span>
        <Telephone :msisdn="msisdn" v-if="msisdn" />
        <Time v-if="date" :time="date" format="date" />
        <code v-if="code">{{ code }}</code>
        <Gender v-if="gender" :gender='gender' />
        <LocationLink v-if="location" :location='location' />
        <span v-if="number">
          {{ number}}
          <span class="caption" v-if="unit">{{unit}}</span>
        </span>
        <v-chip color="accent" small v-if="status" class="text-capitalize">{{status}}</v-chip>
        <IdentityNumber v-if="identityNumber" :demographic="identityNumber" />
        <Currency v-if="currency" :value="currency" />
        <Currency v-if="amount" :value="amount" />
        <Currency v-if="balance" :value="balance" />
      </slot>
    </td>

  </tr>
</template>

<script>
  import Time from '@/components/Time';
  import Gender from '@/components/Gender';
  import Telephone from '@/components/Telephone';
  import LocationLink from '@/components/LocationLink';
  import IdentityNumber from '@/components/IdentityNumber';
  import Currency from '@/components/Currency';

  export default {

    components: {
      Time,
      Gender,
      Telephone,
      LocationLink,
      IdentityNumber,
      Currency
    },

    props: ['title', 'bold', 'text', 'msisdn', 'code', 'status', 'number', 'unit', 'gender', 'location', 'date', 'identityNumber', 'currency', 'amount', 'balance'],

    computed: {
      classes() {
        return {
          'text-left': true,
          'font-weight-bold': this.bold,
        }
      }
    },

  };
</script>
