

export const requiredValidation = v => !!v || 'Field is required'


export class HugoValidations {
    requiredValidation(v) {
        return !!v || 'Field is required';
    }
}
