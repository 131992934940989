<template>
  <div>
    <PageHeader
      :title="$t('Plagas y Enfermedades')"
      :subtitle="customer.name"
      icon="mdi-clipboard-account"
      @back="toCustomer"
      back
      @save="submit"
      save
      :valid="formValid"
    />
    <v-form v-model="validForm" ref="growingAndApplications">
      <v-container fluid class="green lighten-4">
        <v-row class="text-center">
          <v-divider></v-divider>
        </v-row>

        <v-row class="green lighten-4">
          <v-col cols="12">
            <v-card-title>{{
              $t("1 ¿Cuales insumos se utilizaron en los semilleros?")
            }}</v-card-title>
          </v-col>
          <v-col cols="12">
            <Pests v-model="formFields.register.seedlings" />
          </v-col>
        </v-row>
        <v-row class="blue lighten-5">
          <v-col cols="12">
            <v-card-title>{{
              $t("2 ¿Cuales insumos utilizó en la preparación del terreno?")
            }}</v-card-title>
          </v-col>
          <v-col cols="12">
            <Pests v-model="formFields.register.fieldChemicals" />
          </v-col>
        </v-row>

        <v-row class="green lighten-4">
          <v-col cols="12">
            <v-card-title>{{
              $t(
                "3 ¿Cuales insumos utilizó en la preparación de las plántulas para la siembra?"
              )
            }}</v-card-title>
          </v-col>
          <v-col cols="12">
            <Pests v-model="formFields.register.transplantingChemicals" />
          </v-col>
        </v-row>
        <v-row class="blue lighten-5">
          <v-col cols="12">
            <v-card-title>{{
              $t(
                "4 ¿Cuales insumos utilizó durante el desarrollo del cultivo hasta Cosecha?"
              )
            }}</v-card-title>
          </v-col>
          <v-col cols="12">
            <Pests v-model="formFields.register.growingChemicals" />
          </v-col>
        </v-row>
        <v-row class="green lighten-4">
          <v-col cols="12">
            <v-card-title>{{
              $t(
                "5 ¿Cuál plan de nutrición utilizó durante el desarrollo del cultivo?"
              )
            }}</v-card-title>
          </v-col>
          <v-col cols="12">
            <Pests v-model="formFields.register.nutritionPlan" />
          </v-col>
        </v-row>

        <v-row class="blue lighten-5">
          <v-col cols="12">
            <v-card-title>{{
              $t("6 ¿Cuál es el porcentaje de Insumos Biológicos aplicados?")
            }}</v-card-title>
          </v-col>
          <v-col cols="12">
            <Pests v-model="formFields.register.biologicalInputsPercentage" />
          </v-col>
        </v-row>
        <v-row class="green lighten-4">
          <v-col cols="12">
            <v-text-field
              v-model="formFields.register.listChallenges"
              type="text"
              :label="$t('7 Describa las dificultades/retos encontrados.')"
              :hint="$t('Describa las dificultades/retos encontrados.')"
              :rules="[rules.requiblue]"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="d-flex justify-space-between flex-wrap">
              <div class="text-left d-flex mr-1 mb-3">
                <v-btn
                  @click="$emit('clearInputs')"
                  class="d-none d-sm-inline"
                  >{{ $t("Clear") }}</v-btn
                >
              </div>

              <div class="text-right d-flex flex-sm-wrap">
                <v-btn
                  @click="$emit('submitAndEdit')"
                  color="primary"
                  :disabled="!validForm && !formValid"
                  class="mr-2 d-sm-inline-block"
                  >{{ $t("SaveAndEdit") }}</v-btn
                >
                <v-btn
                  @click="$emit('submitAndNew')"
                  color="primary d-sm-inline-block"
                  :disabled="!validForm && !formValid"
                  >{{ $t("SaveAndAdd") }}</v-btn
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Pests from "@/views/partners/hr_co/components/Pests";

export default {
  components: {
    PageHeader,
    Pests,
  },
  data() {
    return {
      step: 1,
      validForm: false,
      formFields:{
        assestmentName:"growingAndApplications",
        createdAt: new Date().toISOString(),
        register: {
          seedlings:[],
          fieldChemicals:[],
          transplantingChemicals:[],
          growingChemicals:[],
          nutritionPlan:[],
          biologicalInputsPercentage:[],
          listChallenges:''
        }
      },
      rules: {
        requiblue: (value) => !!value || "Requerido",
        numberRules: [
          (v) => Number.isInteger(Number(v)) || "Numbers only",
          (v) => Number(v) >= 0 || "A positive number please",
          (v) => !/\D/.test(v) || "Numbers please",
        ],
      }
    };
  },

  computed: {
    customerUuid() {
      return this.$route.query.uuid;
    },
    fieldUuid() {
      return this.$route.query.fieldUuid;
    },
    customer() {
      return this.$store.getters.customer(this.customerUuid);
    },
    partnerCode() {
      return this.$store.getters.settings.partnerCode;
    },
    formValid(){
       for (const [key, value] of Object.entries(this.formFields.register)) {
         console.log(value, value.length)
            if(value.length == 0) return false;
      }
      return true;
    }
  },
  methods: {
    toCustomer() {
      this.$router.push({
        name: "ShowCustomer",
        query: { uuid: this.customerUuid },
      });
    },

    submit() {},
  },
};
</script>

<style scoped>
</style>

<i18n>
{
  "en": {
    "Phase 3 - Growing & Applications" :"Phase 3 - Growing & Applications",
    "Register":"Register",
    "Yes": "Si",
    "No": "No",
    "SaveAndEdit": "Salvar o Editar",
    "SaveAndAdd": "Salvar y añadir (Otro Contacto)",
    "Clear": "Reiniciar"
    
  },
  "es": {
    "Phase 3 - Growing & Applications" :"Fase 3 - Desarrollo Cultivo- Aplicaciones",
    "Register":"Registro",
    "Yes": "Si",
    "No": "No",
    "SaveAndEdit": "Salvar o Editar",
    "SaveAndAdd": "Salvar y añadir (Otro Contacto)",
    "Clear": "Reiniciar"
    
  },
  "sw": {
    "Yes": "Ndio",
    "No": "La",
    "SaveAndEdit": "Hifadhi na hariri",
    "SaveAndAdd": "Hifadhi na sajili mwingine",
    "Clear": "Futa"
  }
}
</i18n>