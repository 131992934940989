<template>
  <PageHeader :title="customer.name"  v-if="customer" back save @back="toCustomer" @save="save" :valid='formValid' >


    <FormContainer>
      <v-row>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-checkbox
            v-model="isAnUzimaAgent"
            :label="$t(isAnUzimaAgent ? 'isAnAgent' : 'isNotAnAgent')"
          ></v-checkbox>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-checkbox
            v-model="isAnUzimaVillageAmbassador"
            :label="$t(isAnUzimaVillageAmbassador ? 'isAVA' : 'isNotAVA')"
          ></v-checkbox>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-combobox ref="sgn" v-model="savings_group_name" :label="$t('parish')" :hint="$t('parish')" :items="parishes" single-line></v-combobox>
        </v-col>


        <v-col cols="12" sm="6" md="4" lg="3">
          <v-combobox ref="county" v-model="uzimaSubcounty" :items="subcounties" :label="$t('subcounties')" :hint="$t('subcounties')" single-line></v-combobox >
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            :label="$t('profession')"
            :items="professions"
            v-model="primaryIncome"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            :label="$t('transport')"
            :items="vehicles"
            v-model="transport"
          ></v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4" lg="3">
          <v-select
            :label="$t('Estimated monthly earnings')"
            :items="monthlyEarnings"
            v-model="income"
          ></v-select>
        </v-col>

      </v-row>
    </FormContainer>


  </PageHeader>
</template>


<script>
  import uzima from '@/mixins/uzimaUg';

  import PageHeader from '@/components/PageHeader';
  import Row from '@/components/Row';
  import FormContainer from '@/components/FormContainer';
  import DateInput from '@/components/DateInput';
  import GenderInput from '@/components/GenderInput';
  import LanguageInput from '@/components/LanguageInput';

  export default {

    components: { PageHeader, Row, FormContainer, DateInput, GenderInput, LanguageInput },

    data: () => ({

      formValid: true,

      isAnUzimaVillageAmbassador: undefined,
      isAnUzimaAgent: undefined,
      savings_group_name: undefined,
      primaryIncome: undefined,
      transport: undefined,
      leadSource: undefined,
      estimatedMonthlyEarnings: undefined,
      income: undefined,

    }),

    computed: {
      uuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.uuid); },
      parish() { return this.customer.savings_group_name || (this.customer.savingsGroup || {}).name; },
    },

    watch: {
      customer:  {
        immediate: true,
        handler(to) {
          if (to) {
            this.isAnUzimaVillageAmbassador = to.isAnUzimaVillageAmbassador;
            this.isAnUzimaAgent = to.isAnUzimaAgent;
            this.savings_group_name = to.savings_group_name;
            this.uzimaSubcounty = to.uzimaSubcounty;
            this.primaryIncome = to.primaryIncome;
            this.transport = to.transport;
            this.leadSource = to.leadSource;
            this.income = to.demographic.income;
          }
        },
      },
      parish: {
        immediate: true,
        handler(to) {
          this.savings_group_name = to;
        }
      },
    },

    methods: {
      save: async function() {
        this.$refs.sgn.blur();
        this.$refs.county.blur();
        await new Promise((r) => setTimeout(r, 100));
        let customer = this.customer;
        customer.demographic = customer.demographic || {};
        customer.isAnUzimaVillageAmbassador = this.isAnUzimaVillageAmbassador;
        customer.isAnUzimaAgent = this.isAnUzimaAgent;
        customer.savings_group_name = this.savings_group_name;
        customer.uzimaSubcounty = this.uzimaSubcounty;
        customer.primaryIncome = this.primaryIncome;
        customer.transport = this.transport;
        customer.leadSource = this.leadSource;
        customer.demographic.income = this.income;
        customer.demographic.incomePeriod = 'monthly';

        customer.demographic.geographicsArray = this.$store.getters.settings.fieldAgentGeographics || [undefined, undefined, undefined, undefined]
        customer.demographic.geographicsArray[3] = this.uzimaSubcounty;

        this.$store.dispatch('upsertCustomer', customer);
        this.toCustomer();
      },
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.uuid } }); },
    },

    mixins: [uzima],

  }
</script>


<i18n>
{
  "en": {
    "parish": "Parish",
    "subcounties": "Subcounty",
    "profession": "Primary Income",
    "transport": "Transport",
    "isAnAgent": "Is an Agent",
    "isNotAnAgent": "Is NOT an Agent",
    "isAVA": "Is a village ambassador",
    "isNotAVA": "Is NOT a Village Ambassador"
  },
  "es": {
  },
  "sw": {
  }
}
</i18n>