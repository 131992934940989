<template>

  <v-container fluid class="pa-0" >

    <v-row>
      <v-col cols="12" sm="6">
        <v-select
          v-model="identityType"
          :items="identityTypeList"
          :label="$t('identityType.label')"
          no-data-text="Select an Identity Type"
        ></v-select>
      </v-col>

      <v-col cols="12" sm="6" md="4">
        <v-text-field
          v-model="identityNumber"
          :rules="[identityNumberRules]"
          :counter="20"
          :label="$t('identityNumber')"
          :disabled="disableIdentityNumber"
        ></v-text-field>
      </v-col>
    </v-row>

  </v-container>

</template>

<script>
  import i18nUtils from '@/mixins/i18n';

  export default {

    props: {
      value: Object,
    },

    data: () => ({
      identityType: undefined,
      identityNumber: undefined,
    }),

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode },
      disableIdentityNumber() { return ((this.identityType || '')=='' || (this.identityType || '')=='none') },
      result() { return { type: this.identityType, number: this.identityNumber }; },
      identityTypeIds() {
        let r = ['none', 'voters_card', 'national_id', 'drivers_license', 'passport'];
        if (this.partnerCode==='hr_co') { r = ['national_id', 'foreigner_id', 'passport']; }
        return r;
      },
      identityTypeList() {
        return this.i18nArray(this.$t('identityType.items')).filter(e => this.identityTypeIds.includes(e.value));
      }
    },

    watch: {
      value: {
        immediate: true,
        handler(to) {
          if (to) {
            this.identityType = to['type'];
            this.identityNumber = to['number'];
          } else {
            this.identityType = undefined;
            this.identityNumber = undefined;
          }
        }
      },
      result(to) {
        let identity = {
          type: this.identityType,
          number: this.identityNumber,
        }
        this.$emit('input', identity );
      },
    },

    methods: {
      identityNumberRules(v) {
        let value = v || '';
        return (this.identityType=='') || (this.identityType=='none') || /^.{4,20}$/.test(value) || this.$t('errorMessage');
      },
    },

    mixins: [i18nUtils],

  };
</script>



<i18n>
{
  "en": {
    "identityNumber": "Identity Number",
    "errorMessage": "Must be between 4 and 20 characters",
    "identityType": {
      "label": "Identity Document",
      "items": [
        { "label": "none",             "value": "None Provided"     },
        { "label": "voters_card",      "value": "Voters Card"       },
        { "label": "national_id",      "value": "National ID"       },
        { "label": "drivers_license",  "value": "Drivers License"   },
        { "label": "passport",         "value": "Passport"          },
        { "label": "foreigner_id",     "value": "Foreigner ID"      }
      ]
    }
  },
  "es": {
    "identityNumber": "Numero de Identidad",
    "errorMessage": "Debe tener entre 4 y 20 caracteres",
    "identityType": {
      "label": "Documento de Identidad",
      "items": [
        { "label": "none",             "value": "Ninguno proporcionado"     },
        { "label": "voters_card",      "value": "Tarjeta de votantes"       },
        { "label": "national_id",      "value": "Cédula de Ciudadanía"      },
        { "label": "drivers_license",  "value": "Licencia de conducir"      },
        { "label": "passport",         "value": "Pasaporte"                 },
        { "label": "foreigner_id",     "value": "Cédula de Extranjería"     }
      ]
    }
  },
  "sw": {
    "identityNumber": "Namba ya Kitambulisho",
    "errorMessage": "Lazima iwe kati ya herufi 4 na 20",
    "identityType": {
      "label": "Aina ya Kitambulisho",
      "items": [
        { "label": "none",             "value": "Hakuna Iliyotolewa"     },
        { "label": "voters_card",      "value": "Kadi ya Mpiga kura"     },
        { "label": "national_id",      "value": "Kitambulisho cha Taifa" },
        { "label": "drivers_license",  "value": "Leseni ya Udereva"      },
        { "label": "passport",         "value": "Pasipoti"               },
        { "label": "foreigner_id",     "value": "Kitambulisho cha Mgeni" }
      ]
    }
  }
}

</i18n>

