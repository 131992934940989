<template>
  <PageHeader :title="field.name" :subtitle="customer.name" icon="mdi-flower-outline" @subtitle="toField" @back="back" @save="upsert" back save :valid='formValid' v-if="field" >

    <v-form v-model="formValid">

      <v-row no-gutters>

        <v-col cols="12" class="pa-1">

          <v-expansion-panels focusable>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <b>Terreno</b>
                <span class="text-caption text-right me-2">Instructivos Preparacion Terreno</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-select v-model="preparacionTerreno" :items="['Cincel', 'Rastra', 'Rastrillo']" label="¿Tipo de preparacion terreno?" class="text-left"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field v-model="distancia_entre_surcos" label="¿Distancia entre surcos?" type="number" suffix="m"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field v-model="altura_camas" label="¿Altura Camas?" type="number" suffix="m"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field v-model="distancia_entre_plantas" label="¿Distancia entre plantas?" type="number"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field v-model="total_plantas" label="¿Total Plantas?" type="number"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select v-model="tutorado" :items="['Si', 'No']" label="¿Tutorado?" class="text-left"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field v-model="distancia_tutorado" label="¿Distancia Tutorado?" type="number" suffix="m"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <DateInput
                        v-model="fetcha_definida_transplante"
                        label="¿Fetcha definida Transplante?"
                        :min="new Date().toISOString().substr(0, 10)"
                        :max="new Date(Date.now() + 45*24*60*60*1000).toISOString().substr(0, 10)"
                      />
                    </v-col>
                  </v-row>
                </v-container>

              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <b>Riego</b>
                <span class="text-caption text-right me-2">Instructivos Sistema de riego</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-select v-model="sistema_de_riego" :items="['Overhead', 'Drip', 'Flood']" label="¿Cual sistema de riego utilizara?" class="text-left"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field v-model="distancia_entre_goteros" label="¿Distancia entre goteros?" type="number" suffix="m"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field v-model="flujo_por_gotero" label="¿Flujo por gotero?" type="number" suffix="l/h"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <DateInput
                        v-model="fetcha_definida_reigo"
                        label="¿Fecha definida para instalacion sstema riego?"
                        :min="new Date().toISOString().substr(0, 10)"
                        :max="new Date(Date.now() + 45*24*60*60*1000).toISOString().substr(0, 10)"
                      />
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-select v-model="prueba_sistema_riego" :items="['Si', 'No']" label="¿Hizo prueba del sistem de riego?" class="text-left"></v-select>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <DateInput
                        v-model="cuando_reigo"
                        label="¿Cuando?"
                        :min="new Date().toISOString().substr(0, 10)"
                        :max="new Date(Date.now() + 45*24*60*60*1000).toISOString().substr(0, 10)"
                      />
                    </v-col>
                  </v-row>
                </v-container>

              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header>
                <b>Emplasticado</b>
                <span class="text-caption text-right me-2">Instructivos Emplasticado</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>

                <v-container>
                  <v-row>
                    <v-col cols="12" sm="4">
                      <v-text-field v-model="mulch" label="¿Tipo de mulch - medidas?" type="number" suffix="m"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="4">
                      <v-text-field v-model="distancia_perforaciones" label="¿Diseno - Distancias perforaciones?" type="number" suffix="l/h"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>

              </v-expansion-panel-content>
            </v-expansion-panel>

          </v-expansion-panels>

        </v-col>

      </v-row>

    </v-form>

  </PageHeader>
</template>


<script>
  import fields from '@/mixins/fields';

  import PageHeader from '@/components/PageHeader';
  import DateInput from '@/components/DateInput';

  export default {

    components: { PageHeader, DateInput },

    data: () => ({
      formValid: true,
      field: undefined,

      preparacionTerreno: undefined,
      distancia_entre_surcos: undefined,
      altura_camas: undefined,
      distancia_entre_plantas: undefined,
      total_plantas: undefined,
      tutorado: undefined,
      distancia_tutorado: undefined,
      fetcha_definida_transplante: undefined,

      sistema_de_riego: undefined,
      distancia_entre_goteros: undefined,
      flujo_por_gotero: undefined,
      fetcha_definida_reigo: undefined,
      prueba_sistema_riego: undefined,
      cuando_reigo: undefined,

      mulch: undefined,
      distancia_perforaciones: undefined,

      // cropRules: [ v => !!v || "Please select a crop" ],
      // numberRules: [
      //   v => Number.isInteger(Number(v)) || 'Numbers only',
      //   v => (Number(v) >= 0) || 'A positive number please',
      //   v => !(/\D/.test(v)) || 'Numbers please',
      // ],
    }),

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
    },

    watch: {
      customer: {
        immediate: true,
        handler(to) {
          if (to) {
            if (!to.fields) { this.$set(to, 'fields', []); }
          }
        },
      },
      fieldUuid: {
        immediate: true,
        handler(to) {
          if (to && this.customer) {
            if (to=='new') {
              this.field = this.newField();
            } else {
              this.field = this.customer.fields.filter( f => f.uuid==to )[0];
            }
            this.field.numberOfPlants = this.field.numberOfPlants || 0;
          } else {
            this.field = undefined;
          }
        },
      }
    },

    methods: {
      upsert() {
        this.field.name = this.deriveFieldName(this.field);
        this.updateCustomerField(this.field);
        this.$store.dispatch('upsertCustomer', this.customer);
        this.toField();
      },
      back() {
        if (this.field.uuid) {
          this.toField();
        } else {
          this.toCustomer();
        }
      },
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
      toField() { this.$router.push({name: 'Field', query: { uuid: this.customerUuid, fieldUuid: this.field.uuid }}); },
    },

    mixins: [fields],

  }
</script>


<i18n>
{
  "en": {
    "fieldName": "Field Name",
    "size": "Size",
    "plants": "Plants"
  },
  "es": {
    "fieldName": "Nombre del campo",
    "size": "Tamaño",
    "plants": "Total Plantas"
  },
  "sw": {
    "fieldName": "Jina la Shamba",
    "size": "Ukubwa",
    "plants": "Mimea"
  }
}
</i18n>
