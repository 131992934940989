<template>
  <v-stepper-content step="3" class="ml-0">
    <v-form v-model="valid" ref="form3">
      <v-container fluid class="py-4">
        <v-row>
            <div class="text-left" style="min-height: 160px">
              <h4>Front of NIN Card</h4>

              <small
                  v-if="showImageValidation"
                  class="form-text text-muted error--text">Image Required</small>
              <br />

              <TakePhoto @click="saveImage" text="Take Photo">
                <v-btn color="primary" small dense class="ml-1"
                  ><v-icon dense class="mr-1">mdi-camera-outline</v-icon>
                  <span class="hidden-sm-and-down">{{
                    $t("Take Photo")
                  }}</span></v-btn
                >
              </TakePhoto>
              <SingleImage :image="formFields.ninCardFrontImage" :maxHeight="150" class="mt-2" />
            </div>

          <v-col cols="12" sm="6">
<!--            <SingleImage :image="formFields.ninCardFrontImage" :maxHeight="150" />-->
          </v-col>
        </v-row>
        <v-row> </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="formFields.ninNumber"
              @keyup="uppercase"
              :label="$t('NIN number')"
              :rules="[rules.required, rules.maxLength]"
              :counter="14"
              
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="formFields.cardNumber"
              :label="$t('Card number')"
              :rules="[maxLengthValidator10]"
              :counter="10"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-btn color="primary" class="mr-3 mb-2 d-xs-block" @click="validateAndContinue" :disabled="!valid || !isImageSelected" >Save & Continue</v-btn>
          <v-btn color="gray darken-3" class="mb-2" @click="previous">Back</v-btn>
        </v-row>
      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>
import TakePhoto from "@/components/TakePhoto";
import SingleImage from "@/components/SingleImage";

export default {
  name: "NinaCardFront",
  emits:["ninFrontImage"],
  components: {
    TakePhoto,
    SingleImage,
  },
  props: ["rules", "value", "next", "previous", "customerUuid"],
  computed: {
    isImageSelected() {
      return !!this.formFields.ninCardFrontImage.uuid
    }

  },
  data(){
    return {
      valid: false,
      formFields: {},
      showImageValidation: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.formFields = to;
      },
    },
    formFields(to) {
      this.$emit("input", to);
    },
  },
  methods: {
    saveImage(uuid) {
     const savedImageObj = {
        type: "ninCardFront",
        type_id: this.customerUuid,
        uuid: uuid,
      };
      this.$store.dispatch("addImage", savedImageObj);
      this.$emit('ninFrontImage', savedImageObj, 'front');
      this.showImageValidation = false
    },
    validate() {
      this.$refs.form3.validate();
      this.showImageValidation = !this.isImageSelected;
    },
    validateAndContinue() {
      // validate all form fields
      this.validate();
      this.next();
    },
     uppercase() {
        this.formFields.ninNumber = this.formFields.ninNumber.toUpperCase();
    },
    maxLengthValidator10: (value) =>
          (value && value.length == 10) ||
          "Nin number must be 10 characters long",
  },
};
</script>

<style>
/* .nina input {
  text-transform: uppercase;
} */
</style>