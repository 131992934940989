<template>

  <v-form v-model="formValid">

    <PageHeader :title="customer.name" icon="mdi-account" @back="toCustomer" @save="submit" :valid='formValid' back save v-if="customer" >

      <v-container>

        <v-row v-if="partnerCode==='uzima_ug'">
          <v-col cols="12">
            <NameInput v-model="customer.name" :label="$t('name')" />
          </v-col>
        </v-row>

        <v-row v-else>
          <v-col cols="12" sm="6">
            <NameInput v-model="customer.firstName" :label="$t('firstName')" />
          </v-col>

          <v-col cols="12" sm="6">
            <NameInput v-model="customer.surname" :label="$t('surname')" />
          </v-col>
        </v-row>
      

      
        <v-row>
          <v-col cols="12" sm="6">
            <MsisdnInput v-model="customer.msisdn" />
          </v-col>
          <v-col cols="12" sm="6">
            <EmailInput v-model="customer.email" />
          </v-col>
        </v-row>

        <v-row v-if="show('photeTypes')">
          <v-col cols="12">
            <v-radio-group v-model="customer.phoneType" row dense class="hidden-xs-only" >
              <v-radio :label="$t('featurePhone')" value="feature"></v-radio>
              <v-radio :label="$t('smartPhone')" value="smart"></v-radio>
              <v-radio :label="$t('sharedSIM')" value="shared"></v-radio>
            </v-radio-group>
            <v-radio-group v-model="customer.phoneType" row dense class="hidden-sm-and-up" >
              <v-radio :label="$t('featurePhoneShort')" value="feature"></v-radio>
              <v-radio :label="$t('smartPhoneShort')" value="smart"></v-radio>
              <v-radio :label="$t('sharedSIMShort')" value="shared"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      

      
        <v-row>
          <v-col cols="12">
            <IdentityInput v-model="customer.demographic.identity" />
          </v-col>
        </v-row>

      </v-container>
      

    </PageHeader>

  </v-form>

</template>


<script>
  import displaySwitches from '@/mixins/displaySwitches';

  import PageHeader from '@/components/PageHeader'

  import FormContainer from '@/components/FormContainer';
  import MsisdnInput from '@/components/MsisdnInput';
  import EmailInput from '@/components/EmailInput';
  import NameInput from '@/components/NameInput';
  import IdentityInput from '@/components/IdentityInput'

  export default {

    components: { PageHeader, FormContainer, MsisdnInput, EmailInput, NameInput, IdentityInput },

    data: () => ({
      formValid: true,
      identity: {},
    }),

    computed: {
      uuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.uuid); },
      partnerCode() { return this.$store.getters.settings.partnerCode; },
    },

    watch: {
      customer: {
        immediate: true,
        handler(to) {
          if (to) { to.demographic = to.demographic || {}; }
        }
      }
    },

    methods: {
      submit() {
        let c = this.customer;
        if (c.firstName || c.surname) { c.name = [c.firstName, c.surname].filter(e => e).map(e => e.trim()).join(' '); }
        this.$store.dispatch('upsertCustomer', c);
        this.toCustomer();
      },
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.uuid } }); },
      identityNumberRules(value) { return (this.customer.demographic.identityType=='') || (this.customer.demographic.identityType=='none') || /^.{4,20}$/.test(value) || 'Must be between 4 and 20 characters'; },
    },

    mixins: [displaySwitches],

  }
</script>


<i18n>
{
  "en": {
    "featurePhone": "Feature Phone",
    "smartPhone": "Smart Phone",
    "sharedSIM": "Shared SIM",
    "featurePhoneShort": "Feature",
    "smartPhoneShort": "Smart",
    "sharedSIMShort": "Shared SIM"
  },
  "es": {
    "featurePhone": "Teléfono inteligente básico",
    "smartPhone": "Teléfono inteligente",
    "sharedSIM": "Tarjeta SIM compartida",
    "featurePhoneShort": "Básico",
    "smartPhoneShort": "Inteligente",
    "sharedSIMShort": "SIM"
  },
  "sw": {
    "featurePhone": "Kiswaswadu",
    "smartPhone": "Simu janja",
    "sharedSIM": "SIM Kadi ya ushirikiano",
    "featurePhoneShort": "Kiswaswadu",
    "smartPhoneShort": "Simu janja",
    "sharedSIMShort": "SIM"
  }
}
</i18n>
