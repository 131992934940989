// import { api } from '@/plugins/api';

const appState = store => {
   
  store.subscribe( ( mutation, state ) => {

    if (mutation.type=='updateOnlineSearch') {
      if ((mutation.payload || '').length<3 ) {
        store.commit('setSearchResults', []);
      } else {
        postMutation(state, mutation).then(json => {store.commit('setSearchResults', json ) });
      }
    }

    if (mutation.type=='retrieveDetailForCustomers') {
      postMutation(state, mutation).then(json => {store.commit('addCustomerDetailsToCache', json ) }).then( json => { store.dispatch('confirmAllCustomersCached') } );
    }


    if (mutation.type=='upsertCustomer') {
      postMutation(state, mutation).then(json => {store.commit('postCustomerUpsert', json ) });
    }

    if (mutation.type=='deleteCustomer') { postMutation(state, mutation); }
    if (mutation.type=='deleteDelivery') { postMutation(state, mutation); }
    if (mutation.type=='upsertField')    { postMutation(state, mutation); }
    if (mutation.type=='addTree')        { postMutation(state, mutation); }
    if (mutation.type=='addPurchase')    { postMutation(state, mutation); }
    if (mutation.type=='addOrder')       { postMutation(state, mutation); }
    if (mutation.type=='deleteOrder')    { postMutation(state, mutation); }
    if (mutation.type=='addReceipt')     { postMutation(state, mutation); }
    if (mutation.type=='addDelivery')    { postMutation(state, mutation); }
    
  });



  let baseUrl = undefined;
  if (process.env.NODE_ENV === 'production') {
    baseUrl = 'https://connect.sasa.solutions/api/app/mutation/';
  } else {
    baseUrl = 'http://api.sasa.localhost:3000/api/app/mutation/';
  }

  function postMutation(state, mutation) {

    let payload = {
      mutation_type: mutation.type,
      mutation: mutation.payload,
    };
    if (state.geolocation && state.geolocation.coords) {
      payload['geolocation'] = {
        latitude:  state.geolocation.coords.latitude,
        longitude: state.geolocation.coords.longitude,
      };
    }

    return fetch(baseUrl + mutation.type + '/', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Accept': 'application/json',
        'Authorization': state.apiKey,
        'Content-Type': 'application/json',
      },
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(payload)
    }).then(response => response.json());

  }



  const apiUrl = function(apiCall) {
    return `${baseURL}${apiCall}`;
  }

}

export { appState };