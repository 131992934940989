<template>

  <v-form v-model="valid" class="red lighten-5" ref="form2">
    <v-card-title>IRRIGATION</v-card-title>
    <v-divider></v-divider>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="4">
          <v-radio-group
            label="1 ¿Sistema de riego por goteo?"
            hint="¿Sistema de riego por goteo?"
            persistent-hint
            row
            :rules="[sharedValidations.requiredValidation]"
            :value="sheet5Phase2FormField.typeOfMulch"
            v-on:change="setHugoFormFields('typeOfMulch', $event)"
            clearable
            required
          >
            <v-radio label="Si" value="yes"></v-radio>
            <v-radio label="No" value="no"></v-radio>
          </v-radio-group>
        </v-col>
        <!--3-->
        <v-col cols="12" md="4">
          <v-select
            persistent-hint
            :items="['0.1', '0.15', 0.2, 0.3]"
            label="2 ¿Distancia entre goteros (Mts) ?"
            hint="¿Distancia entre goteros (Mts) ?"
            required
            :rules="[sharedValidations.requiredValidation]"
            v-model="formFields.drainageTrenches"
            clearable
          ></v-select>
        </v-col>

        <!--4-->
        <v-col cols="12" md="4">
          <v-text-field
            persistent-hint
            label="3 ¿Flujo por gotero?"
            hint="¿Flujo por gotero?"
            type="number"
            required
            :rules="[sharedValidations.requiredValidation]"
            :value="sheet5Phase2FormField.dripperFlow"
            v-on:change="setHugoFormFields('dripperFlow', $event)"
            clearable
          ></v-text-field>
        </v-col>
        <!--5-->
        <v-col cols="12" md="4">
          <v-combobox
            persistent-hint
            :items="[
              'INYECCION POR MODULO',
              'FLUJOMETRO',
              'SIMPLE PEQUEÑO',
              'DE ANILLOS',
              'DE ARENA',
              'PISCINAS DE DECANTACION',
              'NO INSTALARA',
            ]"
            label="4 ¿Qué tipo de Filtro se usará?"
            hint="¿Qué tipo de Filtro se usará?"
            single-line
            :rules="[sharedValidations.requiredValidation]"
            :value="sheet5Phase2FormField.typeOfFilterInTheSystem"
            v-on:change="setHugoFormFields('typeOfFilterInTheSystem', $event)"
            clearable
          ></v-combobox>
        </v-col>
        <!--6-->
        <v-col cols="12" md="4">
          <DateInput
              persistent-hint
            label="5 ¿Fecha definida para instalación sistema riego?"
            hint="¿Fecha definida para instalación sistema riego?"
            :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
            :max="new Date(Date.now() +    90*24*60*60*1000).toISOString().substr(0, 10)"
            :value="sheet5Phase2FormField.dateForInstallingIrrigationSystem"
            v-on:input="setHugoFormFields('dateForInstallingIrrigationSystem', $event)"
              :rules="[sharedValidations.requiredValidation]"
            clearable
          />
        </v-col>
        <!--7-->
        <v-col cols="12" md="4">
          <v-radio-group
            label="6 ¿Hizo prueba del sistema de riego?"
            hint="¿Hizo prueba del sistema de riego?"
            persistent-hint
            row
            :value="sheet5Phase2FormField.irrigationSystemWasTested"
            v-on:change="setHugoFormFields('irrigationSystemWasTested', $event)"
            :rules="[sharedValidations.requiredValidation]"
            clearable
          >
            <v-radio label="Si" value="yes"></v-radio>
            <v-radio label="No" value="no"></v-radio>
          </v-radio-group>
        </v-col>
        <!--8-->
        <v-col cols="12" md="4">
          <DateInput
            persistent-hint
            label="7 ¿Cuando?"
            hint="¿Cuando?"
            required
            :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
            :max="new Date(Date.now() +    90*24*60*60*1000).toISOString().substr(0, 10)"
            :value="sheet5Phase2FormField.dateWhen"
            v-on:input="setHugoFormFields('dateWhen', $event)"
            :rules="[sharedValidations.requiredValidation]"
            clearable
          />
        </v-col>
        <!--9-->

        <v-col cols="12" md="4">
          <v-text-field
            persistent-hint
            label="8 Foto Sistema de riego instalado"
            hint="Foto Sistema de riego instalado"
            required
            :value="sheet5Phase2FormField.when"
            v-on:change="setHugoFormFields('when', $event)"
            clearable
            :rules="[sharedValidations.requiredValidation]"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import DateInput from "../../components/DateInput";
import {HugoValidations} from "../partners/hr_co/sheet_5/validations/basicValidations";

export default {
  components: { PageHeader, DateInput },
  name: "Phase2Section2",
  data() {
    return {
      valid: false,
      formFields: {},
      sharedValidations: new HugoValidations(),
    };
  },
  methods: {
    validate() {
      this.$refs.form2.validate();
    },
    saveForm() {
      console.log('saveForm')
      this.validate();

    },
    setHugoFormFields(name, value) {
      this.$store.commit('setHugoFormFields',{formType: 'sheet5Phase1Section2',name, value})
    }
  },
  computed: {
    sheet5Phase2FormField() {
      return this.$store.getters.hugoFormFields.sheet5Phase1Section2
    },
  },
};
</script>

<style scoped>
</style>