import DB from '@/store/Db';
import { openDB } from 'idb';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';

export default class ImageDb {

  static storeName = 'images';

  static clear() { DB.clear(this.storeName); }

  static add(image_url) {
    const uuid = uuidv4();
    DB.add(this.storeName, { uuid: uuid, image_url:image_url, uploadaed: false });
    return uuid;
  }

  static addUrlFromServer(url) {
    const uuid = uuidv4();
    DB.add(this.storeName, { uuid: uuid, image_url: url, uploadaed: true });
    return uuid;
  }

  static async url(uuid) {
    const image = await DB.fromKey(this.storeName, uuid);
    return image.image_url;
  }

  static async upload(data) {

    const body = await ImageDb.url(data.uuid);

    let structure = {
      uuid: data.uuid,
      type: data.type,
      type_id: data.type_id,
      authorization: data.apiKey,
      partner_code: data.partnerCode,
      createdAt: DateTime.local().toUTC().toISO(),
      image: body,
    }

    // console.log(structure.uuid);
    // console.log(structure.type);
    // console.log(structure.type_id);
    
    fetch('https://upload.sasa.solutions/', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      // referrerPolicy: 'no-referrer',
      body: JSON.stringify(structure)
    }).then(console.log);

  }


  static async pending() {

    // const db = await openDB('workbox-background-sync');
    const db = await openDB('workbox-background-sync');
    let cursor = await db.transaction('requests').store.openCursor();

    while (cursor) {
      console.log(cursor.key, cursor.value);
      cursor = await cursor.continue();
    }

    // let result = undefined;
    // try {
    //   console.log(11);
    //   const db = await openDB('workbox-background-sync');
    //   console.log(22);
    //   results = await db.transaction('requests').objectStore('requests').getAll();
    //   console.log(33);
    //   console.log(results);
    //   console.log(44);
    // } catch {
    //   result = undefined;
    // }
    // return result;

    return null;
  }

}
