<template>
  <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="show">
    <v-card>
      <v-card-title>
        Hugo Restrepo Ocupaciones
      </v-card-title>

      <v-card-actions class="d-flex flex-column justify-content-start">
        <v-btn color="success" small dense :to="{name: 'HRFirstVisit', query: { uuid: customer.uuid }}" class="text-left">
          <v-icon dense>mdi-star-check-outline</v-icon>
          <span>Sheet 2: Primera Visita</span>
        </v-btn>
         <v-btn color="success" small dense :to="{name: 'FieldContractRegister', query: { uuid: customer.uuid }}">
          <v-icon dense>mdi-clipboard-account</v-icon>
          <span>Sheet 3: Cultivo Contrato</span>
        </v-btn>
       <v-btn color="success" small dense :to="{name: 'PreparationOfField', query: { uuid: customer.uuid }}">
          <v-icon dense>mdi-clipboard-account</v-icon>
          <span> Sheet 5: Fase 1 - DISEÑO Y PREPARACION LOTE </span>
        </v-btn>

        <v-btn color="success" small dense :to="{name: 'SeedlingsAndTransplant', query: { uuid: customer.uuid }}">
          <v-icon dense>mdi-clipboard-account</v-icon>
          <span>Sheet 5: Fase 2 - PLANTULAS Y TRANSPLANTE</span>
        </v-btn>
        <v-btn color="success" small dense :to="{name: 'GrowingAndApplications', query: { uuid: customer.uuid }}">
          <v-icon dense>mdi-clipboard-account</v-icon>
          <span>Fase 3 - DESARROLLO CULTIVO APLICACIONES</span>
        </v-btn>
        <v-btn color="success" small dense :to="{name: 'Phase4View', query: { uuid: customer.uuid }}">
          <v-icon dense>mdi-clipboard-account</v-icon>
          <span>Sheet 5: Fase 4 - COSECHA</span>
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-col>
</template>

<script>
  export default {
    props: ['customer'],

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      totalFilledHugoFormFields() {
        return Object.values(this.$store.getters.hugoFormFields.sheet5Phase1Section1).filter(form => !!form).length;
      },
      totalFilledHugoFormFieldsSheet5Phase2() {
        return Object.values(this.$store.getters.hugoFormFields.sheet5Phase1Section2).filter(form => !!form).length;
      },
      show() {
        let r = false;
        if (this.partnerCode==='hr_co') {
          r = true;
        }
        return r;
      }
    },

  }
</script>
