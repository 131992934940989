<template>
  <div>
    <PageHeader
      :title="customer.name || phoneNumberFromMsisdn"
      icon="mdi-flower-outline"
      @back="toCustomers"
      @save="save"
      back
      :valid="true"
      validate
      @validate="validate"
    />

    <v-stepper v-model="step" vertical>
      <v-stepper-step
        step="1"
        :complete="step > 1"
        >Personal Details</v-stepper-step
      >
      <PersonalDetails
        v-model="formFields.personalDetails"
        :rules="rules"
        :next="next"
        ref="form1"
      />

      <v-stepper-step step="2" :complete="step > 2"
        >Income Details</v-stepper-step
      >
      <IncomeDetails
        :rules="rules"
        :next="next"
        :previous="previous"
        ref="form2"
        v-model="formFields.incomeDetails"
      />

      <v-stepper-step step="3" :complete="step > 3"
        >NIN Card Details Front</v-stepper-step
      >
      <NinaCardFront
        :rules="rules"
        :next="next"
        :previous="previous"
        ref="form3"
        v-model="formFields.ninCardFront"
        :customerUuid="customerUuid"
        @ninFrontImage="handleImage"
      />

      <v-stepper-step step="4" :complete="step > 4">
        NIN Card Details Back
      </v-stepper-step>
      <NinaCardBack
        :rules="rules"
        :next="next"
        :previous="previous"
        ref="form4"
        :customerUuid="customerUuid"
        v-model="formFields.ninCardBack"
        @ninaBackImage="handleImage"
      />

      <v-stepper-step step="5" :complete="step > 5">
        Season And Harvest
      </v-stepper-step>

      <SeasonHarvestTable
        :rules="rules"
        :next="next"
        :previous="previous"
        ref="form5"
        v-model="formFields.seasonsAndHarvests"
      />

      <v-stepper-step step="6" :complete="step > 6">Primary Garden</v-stepper-step
      >
      <Map
        :formFields="formFields"
        :rules="rules"
        :next="next"
        :previous="previous"
        ref="form6"
        v-model="formFields.farmLocation"
      />

      <v-stepper-step step="7" :complete="step > 7">
        Loan Input Request
      </v-stepper-step>
      <FarmersNeeds
        :rules="rules"
        :next="next"
        :previous="previous"
        ref="form7"
        v-model="formFields.farmersNeeds"
      />

      <v-stepper-step step="8" :complete="step > 8">
        Otp Acknowledgement
      </v-stepper-step>
      <OtpAcknowledgement
        :rules="rules"
        :capital="formFields.farmersNeeds.total"
        :next="concludeLater"
        :submit="submitApplication"
        :previous="previous"
        ref="form8"
        v-model="formFields.otpAcknowledgement"
      />

      <v-stepper-step step="9"> Thank You </v-stepper-step>

      <ThankYou :name="formFields.name"/>
    </v-stepper>
    <!-- end stepper -->
  </div>
</template>

<script>
import msisdnToTelephone from '@/mixins/msisdnToTelephone';

import PersonalDetails from "@/views/partners/nile_ug/components/PersonalDetails.vue";
import NinaCardFront from "@/views/partners/nile_ug/components/NinaCardFront.vue";
import NinaCardBack from "@/views/partners/nile_ug/components/NinaCardBack.vue";
import SeasonAndHarvest from "@/views/partners/nile_ug/components/SeasonAndHarvest.vue";
import IncomeDetails from "@/views/partners/nile_ug/components/IncomeDetails.vue";
import PageHeader from "@/components/PageHeader";
import Map from "@/views/partners/nile_ug/components/Map.vue";
// import Map2 from "@/views/partners/nile_ug/components/Map2";
import LocationInput from "@/components/LocationInput";
import FarmersNeeds from "@/views/partners/nile_ug/components/FarmersNeeds";
import OtpAcknowledgement from "@/views/partners/nile_ug/components/OtpAcknowledgement.vue";
import ThankYou from "@/views/partners/nile_ug/components/ThankYou.vue";
import SeasonHarvestTable from "@/views/partners/nile_ug/components/SeasonHarvestTable";

export default {

  name: "NileStepperRegistrationForm",

  components: {
    PageHeader,
    PersonalDetails,
    IncomeDetails,
    NinaCardFront,
    NinaCardBack,
    SeasonAndHarvest,
    Map,
    LocationInput,
    FarmersNeeds,
    OtpAcknowledgement,
    ThankYou,
    SeasonHarvestTable
  },

  data: function () {
    return {
      customer: undefined,
      valid: false,
      formFields: undefined,
      step: 1,
      rules: {
        required: (value) => (value==0 || !!value) || "Required",
        maxLength: (value) =>
          (value && value.length == 14) ||
          "Nin number must be 14 characters long",
        fcsLength: (value) =>
          ( ((value || '').length == 0) || ((value || '').length == 10) ) ||
          "FCS number must be 10 digits long",
        numberRules: [
          (v) => Number.isInteger(Number(v)) || "Numbers only",
          (v) => Number(v) >= 0 || "A positive number please",
          (v) => !/\D/.test(v) || "Numbers please",
        ],
        matchingDependents: (value) => {
          if (
            this.formFields.personalDetails.schoolGoingDependent <=
            this.formFields.personalDetails.dependents
          ) {
            return true;
          } else {
            return "School going dependents should be less than or equal to number of dependents";
          }
        },
        maxDependents: (value) =>
          ( (!(value==null)) && value <= 20) || "Cannot exceed 20 dependents",
      },
    };
  },
  computed: {
    customerUuid() { return this.$route.query.uuid; },
    isStart() { return this.step == 1; },
    isLastStep() { return this.step == 9; },
    msisdn() { return this.customer.msisdn; },
  },
  watch: {
    step() { this.save(); },
  },
  methods: {
    next() { this.step += 1; },
    previous() { this.step -= 1; },
    concludeLater() {
      this.save();
      this.toCustomers();
    },
    toCustomers() {
      this.$router.push({ name: 'Customers' });
    },
    validate() {
      // validate the different forms here
      this.$refs.form1.validate();
      this.$refs.form2.validate();
      this.$refs.form3.validate();
      this.$refs.form4.validate();
      this.$refs.form5.validate();
      this.$refs.form6.validate();
      this.$refs.form7.validate();
      this.$refs.form8.validate();
    },
    handleImage(imageObj, side) {
      if (side === "front") {
        this.formFields.ninCardFront.ninCardFrontImage = imageObj;
      } else {
        this.formFields.ninCardBack.ninCardBackImage = imageObj;
      }
    },

    submitApplication() {
      this.customer.nileUgApplication = true;
      this.save();
      this.next();
    },

    save() {

      this.customer.name = this.formFields.personalDetails.name;

      this.customer.demographic = {
        gender: this.formFields.personalDetails.gender,
        birthdate: this.formFields.personalDetails.birthdate,
        dependents: this.formFields.personalDetails.dependents,
        schooling: this.formFields.personalDetails.schooling,

      //   identity: {
      //     type: 'NIN Number',
      //     number: this.formFields.ninNumber,
      //   },

      };

      this.customer.personalDetails = this.formFields.personalDetails;
      this.customer.incomeDetails = this.formFields.incomeDetails;
      this.customer.ninCardFront = this.formFields.ninCardFront;
      this.customer.ninCardBack = this.formFields.ninCardBack;
      this.customer.seasonsAndHarvests = this.formFields.seasonsAndHarvests;
      this.customer.farmersNeeds = this.formFields.farmersNeeds;
      this.customer.otpAcknowledgement = this.formFields.otpAcknowledgement;
      this.customer.farmLocation = this.formFields.farmLocation;

      this.customer.lastNileSubmissionPage = Math.max(...[ parseInt(this.customer.lastNileSubmissionPage || 0), this.step ]);
      this.customer.nileSubmissionAt = (new Date()).toISOString();

      this.$store.dispatch("upsertCustomer", this.customer);

    },
  },
  mounted() {

    this.step = 1;
    this.customer = this.$store.getters.customer(this.$route.query.uuid);

    this.formFields = {};

    // read it out of previously saved data
    this.formFields.personalDetails = this.customer.personalDetails || {};
    this.formFields.incomeDetails = this.customer.incomeDetails || {};
    this.formFields.ninCardFront = this.customer.ninCardFront || {};
    this.formFields.ninCardBack = this.customer.ninCardBack || {};
    this.formFields.seasonsAndHarvests = this.customer.seasonsAndHarvests || [];
    this.formFields.farmersNeeds = this.customer.farmersNeeds || {};
    this.formFields.otpAcknowledgement = this.customer.otpAcknowledgement || {};
    this.formFields.farmLocation = this.customer.farmLocation || {};


    // now, if we are working with details loaded from the server
    this.formFields.personalDetails.name = this.customer.name;

    this.formFields.personalDetails.gender = this.customer.demographic.gender || this.formFields.personalDetails.gender;
    this.formFields.personalDetails.birthdate = this.customer.demographic.birthdate || this.formFields.personalDetails.birthdate;
    this.formFields.personalDetails.dependents = this.customer.demographic.dependents || this.formFields.personalDetails.dependents;
    this.formFields.personalDetails.schooling = this.customer.demographic.schooling || this.formFields.personalDetails.schooling;


    // this.formFields.personalDetails.name: undefined,
    // this.formFields.personalDetails.msisdn: undefined,
    // this.formFields.personalDetails.gender: undefined,
    // this.formFields.personalDetails.dependents: undefined,
    // this.formFields.personalDetails.schoolGoingDependent: undefined,
    // this.formFields.personalDetails.schooling: undefined,
    // this.formFields.personalDetails.birthdate: undefined,

    this.formFields.incomeDetails.fcsCardNumber = this.formFields.incomeDetails.fcsCardNumber || "";
    // this.formFields.incomeDetails.doYouOwnLand: undefined,
    // this.formFields.incomeDetails.yearsAsSupplier: undefined,
    // this.formFields.incomeDetails.agricultureExperience: undefined,
    this.formFields.incomeDetails.assetsHeld = this.formFields.incomeDetails.assetsHeld || [];
    // this.formFields.incomeDetails.alternativeIncomeSource: undefined,
    this.formFields.incomeDetails.alternativeIncomeAmount = parseInt(this.formFields.incomeDetails.alternativeIncomeAmount);
    // this.formFields.incomeDetails.otherLoans: undefined,


    // this.formFields.ninCardFront.ninNumber: undefined,
    // this.formFields.ninCardFront.cardNumber: undefined,
    this.formFields.ninCardFront.ninCardFrontImage = this.formFields.ninCardFront.ninCardFrontImage || {};
    // this.formFields.ninCardFront.ninCardFrontImage: {
    //   type: undefined,
    //   type_id: undefined,
    //   uuid: undefined,
    //   apiKey: undefined,
    //   partnerCode: undefined,
    // }

    this.formFields.ninCardBack.ninCardBackImage = this.formFields.ninCardBack.ninCardBackImage || {};

    // this.formFields.ninCardBack.ninCardBackImage: {
    //   type: undefined,
    //   type_id: undefined,
    //   uuid: undefined,
    //   apiKey: undefined,
    //   partnerCode: undefined,
    // }

    this.formFields.seasonsAndHarvests = this.formFields.seasonsAndHarvests || [];

    // this.formFields.farmLocation.district = this.formFields.farmLocation.district || "";
    // this.formFields.farmLocation.subCounty = this.formFields.farmLocation.subCounty || "";
    // this.formFields.farmLocation.parish = this.formFields.farmLocation.parish || "";
    // this.formFields.farmLocation.village = this.formFields.farmLocation.village || "";


    this.formFields.farmersNeeds.total = this.formFields.farmersNeeds.total || 0;
    this.formFields.farmersNeeds.tableResults = this.formFields.farmersNeeds.tableResults || [];

    // this.formFields.otpAcknowledgement.otp: undefined,
    // this.formFields.otpAcknowledgement.weatherInsurance: false,


  },

  mixins: [msisdnToTelephone],
};
</script>


<style>
</style>