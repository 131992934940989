<template>
  <IndexCompact :customers="customers" v-if="breakpoint=='xs'" />
  <IndexNormal :customers="customers" v-else />
</template>


<script>
  import IndexCompact from '@/views/customers/partners/nile_ug/IndexCompact';
  import IndexNormal from '@/views/customers/partners/nile_ug/IndexNormal';

  export default {
    components: { IndexCompact, IndexNormal },
    props: ['customers'],
    computed: {
      breakpoint() { return this.$vuetify.breakpoint.name; },
    },
  }
</script>
