<template>
  <v-stepper-content step="4" class="ml-0">
    <v-form v-model="valid" ref="form4">
      <v-container fluid>
        <v-row>
          <div class="text-left" style="min-height: 160px">
            <h4> Back of NIN Card </h4>
            <small
                  v-if="showImageValidation"
                  class="form-text text-muted error--text">Image Required
            </small>

              <br />
            <SingleImage
              :image="formFields.ninCardBackImage"
              :maxHeight="150"
            />
            <br />
            <TakePhoto @click="saveImage" text="Take Photo">
              <v-btn color="primary" small dense class="ml-1"
                ><v-icon dense class="mr-1">mdi-camera-outline</v-icon>
                <span class="hidden-sm-and-down">{{
                  $t("Take Photo")
                }}</span></v-btn
              >
            </TakePhoto>
          </div>
        </v-row>

        <v-row class="mt-4">
          <v-btn color="primary" class="mr-3 mb-2 d-xs-block" @click="validateAndContinue" :disabled="!valid || !isImageSelected" >Save & Continue</v-btn>
          <v-btn color="gray darken-3" @click="previous">Back</v-btn>
        </v-row>
      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>
import TakePhoto from "@/components/TakePhoto";
import SingleImage from "@/components/SingleImage";

export default {
  name: "NinaCardBack",
  emits: ["ninaBackImage"],
  components: {
    TakePhoto,
    SingleImage,
  },
  props: ["rules", "value", "next", "previous", "customerUuid"],
  computed: {
    isImageSelected() {
      return !!this.formFields.ninCardBackImage.uuid
    }
  },
  data() {
    return {
      valid: false,
      formFields: {},
      showImageValidation: false
    };
  },
  watch: {
    value: {
      immediate: true,
      handler(to) {
        this.formFields = to;
      },
    },
    formFields(to) {
      this.$emit("input", to);
    },
  },
  methods: {
    validate() {
      this.$refs.form4.validate();
      this.showImageValidation = !this.isImageSelected;
    },
    saveImage(uuid) {
      const savedImageObj = {
        type: "ninaCardBack",
        type_id: this.customerUuid,
        uuid: uuid,
      };
      this.$store.dispatch("addImage", savedImageObj);
      this.$emit("ninaBackImage", savedImageObj, "back");
      this.showImageValidation = false
    },
    validateAndContinue() {
      this.validate();
      this.next();
    },
  },
};
</script>

<style>
</style>