<template>
  
  <v-container fluid class="pa-0" >
    
    <CustomerDetailHeader :customer="customer" >
      
      <v-row no-gutters v-masonry id="printTarget" >

        <v-col cols="12" sm="6" lg="4" class="pa-1">
          <Basics :customer="customer" />
        </v-col>

        <HrActions :customer="customer" />
  
        <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="showInputOrders">
          <InputOrders :customer="customer" />
        </v-col>

        <v-col cols="12" sm="6" lg="4" class="pa-1"  v-if="show('crop_deliveries')">
          <Deliveries :customer="customer" />
        </v-col>

        <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="show('agriculture')">
          <FarmerBasics :customer="customer" />
        </v-col>

        <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="show('fields')">
          <Fields :customer="customer" />
        </v-col>

        <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="show('demographics')">
          <Demographics :customer="customer" />
        </v-col>

        <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="show('cash_receipts')">
          <Receipts :customer="customer" />
        </v-col>

        <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="show('input_purchases')">
          <Purchases :customer="customer" />
        </v-col>

        <v-col cols="12" sm="3" lg="1" class="pa-1" v-if="profileImage">
          <v-card>
            <SingleImage :image="profileImage" />
          </v-card>
        </v-col>

        <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="show('location_detail')">
          <Location :customer="customer" />
        </v-col>


        <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="locations.length>0">
          <Map :locations="locations" />
        </v-col>

        <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="(coords || []).length>0">
          <MapPolygons :coords="coords" />
        </v-col>

        <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="false">
          <FarmerActions :customer="customer" />
        </v-col>

        <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="(customer.appointments || []).length>0">
          <Appointments :customer="customer" />
        </v-col>

        <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="showAssessments">
          <Assessments :customer="customer" />
        </v-col>

        <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="show('delete_customer')">
          <Delete :customer="customer" />
        </v-col>
        

        <v-col cols="12" sm="6" lg="4" class="pa-1" v-if="partnerCode==='uzima_ug'">
          <UzimaUgDetail :customer="customer" />
        </v-col>

      </v-row>

    </CustomerDetailHeader>

  
    <v-row no-gutters v-if="images.length>0">
      <v-col cols="12" class="pa-1">
        <Images :images="images" />
      </v-col>
    </v-row>
  </v-container>

</template>


<script>
  import displaySwitches from '@/mixins/displaySwitches';

  import Map from '@/components/Map';
  import MapPolygons from '@/components/MapPolygons';
  import Basics from '@/views/customers/components/Basics';
  import Appointments from '@/views/customers/components/Appointments';
  import Fields from '@/views/customers/components/Fields';
  import InputOrders from '@/views/customers/components/InputOrders';
  import Receipts from '@/views/customers/components/Receipts';
  import Purchases from '@/views/customers/components/Purchases';
  import Deliveries from '@/views/customers/components/Deliveries';
  import Images from '@/components/Images';
  import SingleImage from '@/components/SingleImage';
  import CustomerDetailHeader from '@/views/customers/components/CustomerDetailHeader'
  import FarmerActions from '@/views/customers/components/FarmerActions';
  import FarmerBasics from '@/views/farmers/components/Basics';
  import Demographics from '@/views/customers/components/Demographics';
  import Location from '@/views/customers/components/Location';
  import Assessments from '@/views/customers/components/Assessments';
  import Delete from '@/views/customers/components/Delete';
  import UzimaUgDetail from '@/views/customers/partners/uzima_ug/Detail';
  import HrActions from '@/views/customers/partners/hr_co/HrActions';

  export default {

    components: { Map, MapPolygons, Basics, Appointments, Fields, InputOrders, Receipts, Purchases, Deliveries, Images, SingleImage, CustomerDetailHeader, FarmerActions, FarmerBasics, Demographics, Location, Assessments, Delete, UzimaUgDetail, HrActions },

    props: ['customer'],

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode },
      images() {
        let i = this.$store.getters.images.filter( e => ( e.type_id===this.customer.uuid ) && (e.type==='customer' || e.type==='demographic') );
        if (this.customer.demographic && this.customer.demographic.imageUrls) { i = i.concat(this.customer.demographic.imageUrls); }
        if (this.customer.demographic && this.customer.demographic.idScanUrls) { i = i.concat(this.customer.demographic.idScanUrls); }
        if (this.customer.imageUrls) { i = i.concat(this.customer.imageUrls) }
        return i;
      },
      locations() { return (this.customer.demographic ? Object.values(this.customer.demographic.keyLocations || {}) : []).filter( e => e && e.latitude ); },
      profileImage() {
        let image = undefined;
        // these probably are more recent than anything already uploaded
        let images = this.$store.getters.images.filter( e => ( e.type_id===this.customer.uuid ) && (e.type==='customer') );
        if (images.length>0) { image = images[images.length - 1]; }
        if ( (image==null) && this.customer.imageUrls && (this.customer.imageUrls.length>0) ) {
          image = this.customer.imageUrls[this.customer.imageUrls.length - 1];
        }
        return image;
      },
      coords() {
        return (this.customer.fields || []).map(this.fieldCoords).filter(e => e.coordinates);
      },
      showAssessments() { return ((this.customer.assessments || []).length>0); },
      showInputOrders() {
        let s = this.show('input_orders');
        if (s && (this.partnerCode==='uzima_ug') && !(this.customer.isAnUzimaAgent)) {
          s = false;
        }
        // temp
        if (this.partnerCode==='uzima_ug') {
          s = false;
        }
        return s;
      }
    },
    methods: {
      fieldCoords(field) {
        return {
          partnerIdentifier: this.customer.partnerIdentifier,
          fieldId: field.id,
          caption: field.name,
          coordinates: field.map,
        }
      },
    },

    mixins: [displaySwitches],

  }
</script>
