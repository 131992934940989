<template>
  <v-stepper-content step="1" class="ml-0">
    <v-form ref="form1" v-model="valid">
      <v-container fluid>
        <v-row class="blue lighten-5">
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="formFields.name"
              :label="$t('Name')"
              :rules="[rules.required]"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <DateInput
              v-model="formFields.birthdate"
              :label="$t('Date of Birth')"
              min="1910-01-01"
              :rules="[rules.required]"
              :max="new Date().toISOString().substr(0, 10)"
              year="true"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <GenderInput
              label="Gender"
              v-model="formFields.gender"
              required
              :rules="[rules.required]"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              clearable
              :min="0"
              :max="20"
              type="number"
              v-model.number="formFields.dependents"
              :label="$t('Number of dependants')"
              :rules="[rules.required, rules.maxDependents]"
            ></v-text-field>
          </v-col>

         
        </v-row>

        <v-row class="blue lighten-5 mb-3">
           <v-col cols="12" sm="6">
            <v-combobox
              clearable
              hide-selected
              v-model.number="formFields.schoolGoingDependent"
              :label="$t('Number of school going dependents')"
              :rules="[rules.required,  rules.maxDependents, rules.matchingDependents,]"
              :min="0"
              :max="20"
              type="number"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              v-model="formFields.schooling"
              :label="$t('Education level (years)')"
              :rules="[rules.required]"
              :items="schoolingOptions"
            ></v-select>
          </v-col>
        </v-row>

        <v-row class="mt-4">
          <v-btn color="primary" @click="validateAndContinue" :disabled="!valid"> Save Draft and Continue </v-btn>
        </v-row>
      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import FormContainer from "@/components/FormContainer";
import GenderInput from "@/components/GenderInput";
import DateInput from "@/components/DateInput";


export default {
  name: "PersonalDetails",
  components: {
    PageHeader,
    FormContainer,
    DateInput,
    GenderInput,
  },
  data(){
    return {
      valid: false,
      formFields: {}
    }
  },
  props: ["rules", "value", "next", "msisdn"],
  computed: {
    schoolingOptions() {
      return ["Zero Education", "Primary School", "Ordinary Level of Education",
      "Advanced Level of Education", "Tertiary", "University"
      ];
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(to) { this.formFields = to; }
    },
    formFields(to) { this.$emit('input', to); },
  },
  methods:{
    validate() {
      this.$refs.form1.validate();
    },
    validateAndContinue(){
      this.validate();
      this.next();
    }
  }
};
</script>

<style>
</style>