<template>
  <PageHeader :title="$t('Preparation of Field')"
              save @save="saveForm"
              :valid="formValid"
              @validate="saveForm"
              validate
  >
    <v-form v-model="valid" class="green lighten-5" ref="form">
      <v-card-title>REGISTER</v-card-title>
      <v-divider></v-divider>
      <v-container fluid>
        <v-row class="green lighten-5">
          <v-col cols="12" sm="4">
            <v-combobox
              persistent-hint
              :items="['CINCEL', 'RASTRA', 'RASTRILLO']"
              label="1 ¿Tipo de preparación terreno?"
              hint="¿Tipo de preparación terreno?"
              :rules="[sharedValidations.requiredValidation]"
              :value="sheet5Phase1FormField.landPreparation"
              v-on:change="setHugoFormFields('landPreparation', $event)"
              single-line
              clearable
            ></v-combobox>
          </v-col>
          <!--3-->
          <v-col cols="12" md="4">
            <v-select
              persistent-hint
              :items="['Yes', 'No']"
              label="2 ¿Zanjas para Drenaje?"
              hint="¿Zanjas para Drenaje?"
              :rules="[sharedValidations.requiredValidation]"
              :value="sheet5Phase1FormField.drainageTrenches"
              v-on:change="setHugoFormFields('drainageTrenches', $event)"
              required
              clearable
            ></v-select>
          </v-col>
          <!--4-->
          <v-col cols="12" md="4">
            <v-combobox
              persistent-hint
              :value="sheet5Phase1FormField.distanceBetweenRows"
              v-on:change="setHugoFormFields('distanceBetweenRows', $event)"
              :items="['1.4', '1.5']"
              label="3 ¿Distancia entre surcos (Mts) ?"
              hint="¿Distancia entre surcos (Mts) ?"
              :rules="[sharedValidations.requiredValidation]"
              single-line
              clearable
            ></v-combobox>
          </v-col>
          <!--5-->
          <v-col cols="12" md="4">
            <v-combobox
              persistent-hint
              :items="[]"
              label="4 ¿Altura Camas?"
              hint="¿Altura Camas?"
              :rules="[sharedValidations.requiredValidation]"
              single-line
              :value="sheet5Phase1FormField.plantingBedsHeight"
              v-on:change="setHugoFormFields('plantingBedsHeight', $event)"
              clearable
            ></v-combobox>
          </v-col>
          <!--          6-->
          <v-col cols="12" md="4">
            <v-combobox
              persistent-hint
              :items="['0.30', '0.40', '0.50']"
              label="5 ¿Distancia entre plantas (Mts) ?"
              hint="¿Distancia entre plantas (Mts) ?"
              :rules="[sharedValidations.requiredValidation]"
              single-line
              :value="sheet5Phase1FormField.distanceBetweenPlants"
              v-on:change="setHugoFormFields('distanceBetweenPlants', $event)"
              clearable
            ></v-combobox>
          </v-col>
          <!--7-->
          <v-col cols="12" md="4">
            <v-select
              persistent-hint
              :items="['1', '2']"
              label="6 ¿Hileras?"
              hint="¿Hileras?"
              :rules="[sharedValidations.requiredValidation]"
              required
              :value="sheet5Phase1FormField.rows"
              v-on:change="setHugoFormFields('rows', $event)"
              clearable
            ></v-select>
          </v-col>
          <!--8-->
          <v-col cols="12" md="4">
            <v-text-field
              persistent-hint
              label="7 ¿Total Plantas?"
              hint="¿Total Plantas?"
              type="number"
              :rules="[sharedValidations.requiredValidation]"
              required
              :value="sheet5Phase1FormField.totalPlants"
              v-on:change="setHugoFormFields('totalPlants', $event)"
              clearable
            ></v-text-field>
          </v-col>
          <!--9-->
          <v-col cols="12" md="4">
            <v-select
              persistent-hint
              :items="['Yes', 'No']"
              label="8 ¿Tutorado?"
              hint="¿Tutorado?"
              :rules="[sharedValidations.requiredValidation]"
              required
              :value="sheet5Phase1FormField.sticks"
              v-on:change="setHugoFormFields('sticks', $event)"
              clearable
            ></v-select>
          </v-col>

          <!--          10-->

          <v-col cols="12" md="4">
            <v-text-field
              persistent-hint
              label="9 ¿Total tutores?"
              hint="¿Total tutores?"
              type="number"
              :rules="[sharedValidations.requiredValidation]"
              required
              :value="sheet5Phase1FormField.totalSticks"
              v-on:change="setHugoFormFields('totalSticks', $event)"
              clearable
            ></v-text-field>
          </v-col>
          <!--          11-->
          <v-col cols="12" md="4">
            <v-combobox
              persistent-hint
              label="10 ¿Líneas de fibra?"
              hint="¿Líneas de fibra?"
              :rules="[sharedValidations.requiredValidation]"
              required
              :value="sheet5Phase1FormField.linesOfFibers"
              v-on:change="setHugoFormFields('linesOfFibers', $event)"
              clearable
            ></v-combobox>
          </v-col>
          <!--12-->
          <v-col cols="12" md="4">
            <v-combobox
              persistent-hint
              :items="['1', '1.5', '2']"
              label="11 ¿Distancia Tutorado (Mts) ?"
              hint="¿Distancia Tutorado (Mts) ?"
              :rules="[sharedValidations.requiredValidation]"
              required
              :value="sheet5Phase1FormField.dateOfTransplant"
              v-on:change="setHugoFormFields('dateOfTransplant', $event)"
              clearable
            ></v-combobox>
          </v-col>
          <!--13-->

          <v-col cols="12" md="4">
            <DateInput
              label="12 ¿Fecha Estimada Transplante?"
              :rules="[sharedValidations.requiredValidation]"
              required
              :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
              :max="new Date(Date.now() +    90*24*60*60*1000).toISOString().substr(0, 10)"
               v-model="formFields.register.stickDistanceForPlanting"
              clearable
            />
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-divider></v-divider>

    <Phase2Section2 ref="form2" />


    <v-divider></v-divider>

    <Phase2Section3 v-model="formFields.irrigation" ref="form3" />

  </PageHeader>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import Phase2Section2 from "./Phase2Section2";
import DateInput from "../../components/DateInput";
import Phase2Section3 from "./Phase2Section3";
import {HugoValidations} from "../partners/hr_co/sheet_5/validations/basicValidations";

export default {
  components: { PageHeader, Phase2Section2, DateInput, Phase2Section3 },
  name: "PreparationOfField",
  data() {
    return {
      valid: false,
      formFields: {
        assessmentName: 'preparationOfField',
        createdAt: 'dsdgf',
        register: {},
        irrigation: {}
      },
      sharedValidations: new HugoValidations(),
    };
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    saveForm() {
      this.$refs.form2.validate()
      this.$refs.form3.validate()
      this.validate();
    },
    setHugoFormFields(name, value) {
       console.log('setHugoFormFields',name, value)
      this.$store.commit('setHugoFormFields',{formType: 'sheet5Phase1Section1',name, value})
    }
  },
  computed: {
    formValid() { return Object.keys(this.formFields).length==2 },
    sheet5Phase1FormField() {
      return this.$store.getters.hugoFormFields.sheet5Phase1Section1
    },
  }
};
</script>

<style scoped>
</style>