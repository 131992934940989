var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageHeader',{attrs:{"title":_vm.$t('trees'),"icon":"mdi-pine-tree"},scopedSlots:_vm._u([{key:"buttons",fn:function(){return [_c('v-btn',{staticClass:"mr-1",attrs:{"color":"primary","small":"","dense":"","to":{name: 'RegisterTree' }}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-pine-tree")]),_vm._v(" "+_vm._s(_vm.$t('registerTree')))],1),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"primary","small":"","dense":"","to":{name: 'TreeFinder' }}},[_c('v-icon',{attrs:{"dense":""}},[_vm._v("mdi-image-search-outline")]),_vm._v(" "+_vm._s(_vm.$t('search')))],1)]},proxy:true},{key:"menu",fn:function(){return [_c('AppMenuItem',{attrs:{"icon":"mdi-pine-tree","to":{name: 'RegisterTree' },"title":_vm.$t('registerTree')}})]},proxy:true}])},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"width":"100%"},attrs:{"headers":_vm.headers,"items":_vm.trees,"items-per-page":15},scopedSlots:_vm._u([{key:"item.identifier",fn:function(ref){
var item = ref.item;
return [_c('code',[_vm._v(_vm._s(item.identifier))])]}},{key:"item.species",fn:function(ref){
var item = ref.item;
return [_c('i',[_vm._v(_vm._s(item.species))])]}},{key:"item.height",fn:function(ref){
var item = ref.item;
return [(item.height)?_c('NumberFormatter',{attrs:{"value":parseFloat(item.height),"unit":"m"}}):_vm._e()]}},{key:"item.girth",fn:function(ref){
var item = ref.item;
return [(item.girth)?_c('NumberFormatter',{attrs:{"value":parseFloat(item.girth),"unit":"m"}}):_vm._e()]}},{key:"item.health",fn:function(ref){
var item = ref.item;
return [(item.health)?_c('code',[_vm._v(_vm._s(item.health))]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }