import Vue from 'vue';
import { DateTime } from 'luxon';

Vue.filter('regexGroups', (value, regex, delimeter = ' ') => {
  if (!value) return '';
  if (!regex) return value;
  let match = regex.exec(value);
  let result = '';
  for (var i = 1; i < match.length; i++) {
    result = result + (i==1 ? '' : delimeter) + match[i];
  }
  return result;
});


Vue.filter('id_number_title', (value) => {
  return {
    'id_number': 'ID Number',
    'national_id': 'National ID',
  }[value];
});


Vue.filter('time', (value, format) => {

  let t = derivedTime(value);
  let f = formatted(t, format);
  return f;

  function formatted(t, format) {
    let r = null;
    if (t) {
      if (format=='date') {
        const f = "d MMM yyyy";
        r = t.toFormat(f);
      } else if (format=='caption') {
        r = formatCaption(t);
      } else if (format=='time-caption') {
        r = formatTimeCaption(t);
      } else if (format=='ago') {
        const age = -1*t.diffNow('seconds').seconds;
        r = ( (age < 60) ? 'Now' : t.toRelative() );
      } else {
        const f = format || "d MMM yyyy, HH:mm";
        r = t.toFormat(f);
      }
    }
    return r;

    function formatCaption(t) {
      let r = null;
      let diff = DateTime.local().diff(t, 'days').toObject().days;
      if (diff<7) {
        if (diff<2) {
          let today = DateTime.local().startOf('day');
          r = (t>=today ? 'Today' : 'Yesterday');
        } else {
          r = t.toFormat('cccc');
        }
      } else {
        r = t.toFormat('ccc, d MMM');
      }
      return r;
    }

    function formatTimeCaption(t) {
      let r = null;
      let diff = DateTime.local().diff(t, 'days').toObject().days;
      if (diff<1) {
        r = t.toFormat('h:mm a');
      } else {
        r = t.toFormat('ccc, h:mm a');
      }
      return r;
    }

  }


  function derivedTime(value) {
    let t = null;
    if (value) {
      if (typeof value === 'object') {
        if (value.isLuxonDateTime) {
          t = value;
        } else {
          t = DateTime.fromJSDate(value);
        }
      } else if (isNaN(value)) {
        t = DateTime.fromISO(value);
        if (!t.isValid) {
          t = DateTime.fromFormat(value, "yyyy-MM-dd HH:mm:ss z");
        }
      } else {
        let n = parseInt(value);
        if ((n>20200000) && (n<20400000)) {
          t = DateTime.fromISO(value);
        } else {
          if (value > Date.now()/10) {
            t = DateTime.fromMillis(value);
          } else {
            t = DateTime.fromSeconds(value);
          }
        }
      }
      return t;
    }

  }

});