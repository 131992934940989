<template>

  <PageHeader :title="field.name || 'New Field'" icon="mdi-flower-outline" @subtitle="toField" @back="toField" @save="save" back save :valid='valid' >

    <v-form v-model="formValid" v-if="field" ref="contractForm">

      <FormContainer color="orange lighten-5">
        <v-row>

          <v-col cols="12" sm="6">
            <v-select :items="crops" :label="$t('Crop')" class="text-left" v-model="field.cropId" :rules="cropRules"></v-select>
          </v-col>

          <v-col cols="12" sm="6">
            <LandSizeInput label="Field size" v-model="field.fieldSize" required />
          </v-col>

          <v-col cols="12" sm="4">
            <DepotInput v-model="field.depot" required />
          </v-col>

          <v-col cols="12" sm="4">
            <v-select :items="harvestSpecifications" :label="$t('harvestSpecification')" class="text-left" v-model="field.payload['hrHarvestSpecification']" multiple chips :rules="harvestRules"></v-select>
          </v-col>

          <v-col cols="12" sm="4">
            <v-select :items="seedOrigins" :label="$t('seedOrigin')" class="text-left mt-3" v-model="field.payload['hrSeedOrigin']"  :rules="seedRules"></v-select>
          </v-col>

          <v-col cols="12" sm="6">
            <DateInput
              v-model="field.payload['hrContractDate']"
              :label="$t('contractDate')"
              :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
              :max="new Date(Date.now() +    90*24*60*60*1000).toISOString().substr(0, 10)"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <DateInput
              v-model="field.planted_at"
              :label="$t('targetDate')"
              :min="new Date(Date.now() - 24*30*24*60*60*1000).toISOString().substr(0, 10)"
              :max="new Date(Date.now() +   360*24*60*60*1000).toISOString().substr(0, 10)"
            />
          </v-col>

          <v-col cols="12" sm="4">

            <div class="d-flex">
              <v-switch
                v-model="cop"
                flat
              ></v-switch>

              <v-text-field
                v-model="field.payload['hrContractPrice']"
                :rules="[priceRules]"
                :label="$t('contractPrice')"
                min=0
                type='number'
                :prefix="currency"
                suffix="/kg"
                @input='setCurrency()'
              />
            </div>
            
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field v-model="field.partner_identifier" :rules="[fieldCodeRules]" :label="$t('fieldCode')" />
          </v-col>

          <v-col cols="12" sm="4">
            <v-text-field v-model="field.payload['hrPreviousFieldCode']" :rules="[priorFeldCodeRules]" :label="$t('priorFieldCode')" />
          </v-col>

        </v-row>
      </FormContainer>

    </v-form>

  </PageHeader>

</template>

<script>
  import fields from '@/mixins/fields';

  import PageHeader from '@/components/PageHeader';
  import FormContainer from '@/components/FormContainer';
  import DateInput from '@/components/DateInput';
  import LandSizeInput from '@/components/LandSizeInput';
  import DepotInput from '@/components/DepotInput';

  export default {
    components: { PageHeader, FormContainer, DateInput, LandSizeInput, DepotInput },
    data: () => ({
      field: undefined,
      formValid: true,
      harvestSpecifications: ['Verde', 'Roja', 'Amarilla'],
      seedOrigins: ['Hugo Restrepo', 'Otro'],
      cop: false,
      cropRules: [ v => !!v || "Seleccione un cultivo" ],
      harvestRules: [ v => v.length>0 || 'Especifique el tipo de cosecha' ],
      seedRules: [ v => !!v || 'Confirme la fuente de la semilla' ],
    }),
    computed: {
      customerUuid() { return this.$route.query.uuid; },
      fieldUuid() { return this.$route.query.fieldUuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      crops() { return this.$store.getters.crops; },
      currency() { return ( this.cop ? 'COP' : '$' ); },
      valid() {
        return this.formValid && this.field.depot && this.field.fieldSize && parseFloat(this.field.fieldSize['landSize'])>0;
      }
    },
    watch: {
      fieldUuid: {
        immediate: true,
        handler(to) {
          if (to && this.customer) {
            this.field = this.customer.fields.filter( f => f.uuid==to )[0];
            this.field['payload'] = this.field['payload'] || {};
            this.field.depot = this.field.depot || this.customer.depot;
          } else {
            this.field = undefined;
          }
        },
      },
      currency: function() { this.setCurrency(); },
    },
    mounted() {
      this.$refs.contractForm.validate();
    },
    methods: {
      setCurrency() { this.field.payload['hrContractCurrency'] = (this.currency==='COP' ? 'COP' : 'USD'); },
      priceRules(value) {
        let r = (parseFloat(value) >= 0);
        if (!r) { r = 'Ingrese una cantidad positiva';}
        return r;
      },
      fieldCodeRules(value) {
        return /^\d{3}[a-zA-Z]$/.test(value) || 'Código de campo no válido';
      },
      priorFeldCodeRules(value){
        return (value || '').length==0 || /^\d{3}[a-zA-Z]$/.test(value) || 'Código de campo no válido';
      },
      toField() { this.$router.push({name: 'Field', query: { uuid: this.customerUuid, fieldUuid: this.field.uuid }}); },

      save(scores) {
        this.field.name = this.deriveFieldName(this.field);
        this.field.payload['hrContractDetailAt'] = new Date().toISOString();
        this.$store.dispatch('upsertCustomer', this.customer);
        this.toField();
      },

    },

    mixins: [fields],
  }
  
</script>

<i18n>
{
  "en": {
    "Crop": "Crop",
    "harvestSpecification": "Harvest Specification",
    "seedOrigin": "Seed Origin",
    "targetDate": "Target Date for Transplant",
    "contractDate": "Contract Date",
    "contractPrice": "Contract Price",
    "fieldCode": "Field Code",
    "priorFieldCode": "Prior Field Code"
  },
  "es": {
    "Crop": "Cultivo",
    "harvestSpecification": "Especificación de cosecha",
    "seedOrigin": "Origen de la semilla",
    "targetDate": "Fecha objetivo para el trasplante",
    "contractDate": "Fecha del contrato",
    "contractPrice": "Precio de contrato",
    "fieldCode": "Código de campo",
    "priorFieldCode": "Código de campo anterior"
  },
  "sw": {
    "Crop": "Mazao",
    "harvestSpecification": "Uainishaji wa Mavuno",
    "seedOrigin": "Asili ya Mbegu",
    "targetDate": "Tarehe lengwa ya Kupandikiza",
    "contractDate": "Tarehe ya Mkataba",
    "contractPrice": "Bei ya Mkataba",
    "fieldCode": "Nambari ya Shamba",
    "priorFieldCode": "Kanuni ya Mbele ya Shamba"
  }
}
</i18n>
