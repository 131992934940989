<template>
  <v-stepper-content step="2" class="ml-0">
    <v-form v-model="valid" ref="form2">
      <v-container fluid>
        <v-row class="blue lighten-5">
          <v-col cols="12" sm="6" class="text-left">
            <v-text-field
              v-model="formFields.fcsCardNumber"
              :counter="10"
              label="FCS Card Number (if available)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row class="blue lighten-5">
          <v-col cols="12" sm="6">
            <v-radio-group
              row
              dense
              :label="$t('Do you own land')"
              v-model="formFields.doYouOwnLand"
              :rules="[rules.required]"
              required
            >
              <v-radio label="Yes" value="Yes"></v-radio>
              <v-radio label="No" value="No"></v-radio>
            </v-radio-group>
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="formFields.yearsAsSupplier"
              label="Years as supplier to Nile"
              type="number"
              :rules="[rules.required, ...rules.numberRules]"
              :min="0"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="blue lighten-5">
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="formFields.agricultureExperience"
              label="Agriculture Experience in (years)"
              type="number"
              :rules="[rules.required, ...rules.numberRules]"
              :min="0"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              clearable
              v-model="formFields.assetsHeld"
              :label="$t('Assets Held')"
              :items="assetsOptions"
              :rules="[rules.required]"
              multiple
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
              clearable
              v-model="formFields.alternativeIncomeSource"
              label="Alternative Income Source"
              :items="incomeSources"
              :rules="[rules.required]"
              multiple
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              label="Alt. income (per season)"
              v-model="formFields.alternativeIncomeAmount"
              type="number"
              required
              prefix="UGX"
              hint="per season"
              :min="0"
              :rules="[rules.required, ...rules.numberRules]"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row class="blue lighten-5 mb-4">
          <v-col cols="12" sm="6" lg="6" class="text-left">
            <label>{{ $t("Other loans") }}</label>
            <v-radio-group row required :rules="[rules.required]"  v-model="formFields.otherLoans">
              <v-radio label="Yes" value="yes"></v-radio>
              <v-radio label="No" value="no"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row class="mt-4">
          <v-btn color="primary" class="mr-3 mb-2 d-xs-block" @click="validateAndContinue" :disabled="!valid">Save & Continue</v-btn>
          <v-btn color="gray darken-3" class="mb-2" @click="previous">Back</v-btn>
        </v-row>
      </v-container>
    </v-form>
  </v-stepper-content>
</template>

<script>
import GenderInput from "@/components/GenderInput";
import DateInput from "@/components/DateInput";
import MsisdnInput from "@/components/MsisdnInput";

export default {
  name: "IncomeDetails",
  components: {
    DateInput,
    GenderInput,
    MsisdnInput,
  },
  props: ["rules", "value", "next", "previous"],
  data(){
    return {
      valid: false,
      formFields: {},
    }
  },
  computed: {
    schoolingOptions() {
      return [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
    },
    incomeSources() {
      return [
        "Business",
        "Other Crops",
        "Produce Shop",
        "Shop",
        "Medical Personnel",
        "Milling",
        "Police Personnel",
        "Rental",
        "Teaching",
        "Tailoring",
        "Other",
        "None",
      ];
    },
    assetsOptions() {
      return [
        "Cows",
        "Land",
        "Trees",
        "Goats",
        "Coffee",
        "Farm Equipment",
        "Sheep",
        "Poultry",
        "Car",
        "Motorcycle",
        "House",
        "Donkeys",
        "Other",
      ];
    },
  },
   watch: {
    value: {
      immediate: true,
      handler(to) { this.formFields = to; }
    },
    formFields(to) { this.$emit('input', to); },
  },
  methods:{
    validate() {
      this.$refs.form2.validate();
    },
    validateAndContinue(){
      this.validate();
      this.next();
    }
  }
};
</script>

<style>
</style>