<template>
  <span>
    <small class="hidden-xs-only">{{currency}}</small>
    <NumberFormatter :value="value" />
  </span>
</template>



<script>
  import NumberFormatter from '@/components/NumberFormatter';

  export default {

    components: { NumberFormatter },

    props: ['value'],

    computed: {
      currency() { 
        let currency = this.$store.getters.currency;
        const code =  this.$store.getters.settings.partnerCode; 
        
        return code == "nile_ug" ? 'UGX': currency;
      },
      
    },

  }
</script>
