<template>
  <v-form v-model="valid" class="purple lighten-5" ref="form3">
    <v-card-title>PLASTIC MULCH</v-card-title>
    <v-divider></v-divider>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="4">
          <v-radio-group
            label="1 ¿Tipo de mulch?"
            hint="¿Tipo de mulch?"
            persistent-hint
            row
            :rules="[sharedValidations.requiredValidation]"
            :value="sheet5Phase1Section3FormField.typeOfMulch"
            v-on:change="setHugoFormFields('typeOfMulch', $event)"
          >
            <v-radio label="Si" value="yes"></v-radio>
            <v-radio label="No" value="no"></v-radio>
          </v-radio-group>
        </v-col>
<!--3-->
        <v-col cols="12" md="4">
          <v-combobox
            persistent-hint
            :items="[0.6, 0.7, 0.8, 0.9, 1.1, 1.2]"
            label="2 ¿Calibre del Plástico?"
            hint="¿Calibre del Plástico?"
            single-line
            :value="sheet5Phase1Section3FormField.plasticCaliber"
            v-on:change="setHugoFormFields('plasticCaliber', $event)"
            clearable
            :rules="[sharedValidations.requiredValidation]"
          ></v-combobox>
        </v-col>
<!--4-->
        <v-col cols="12" md="4">
          <v-text-field
            persistent-hint
            label="3 ¿Color del Plástico?"
            hint="¿Color del Plástico?"
            required
            :value="sheet5Phase1Section3FormField.plasticColor"
            v-on:change="setHugoFormFields('plasticColor', $event)"
            clearable
            :rules="[sharedValidations.requiredValidation]"
          ></v-text-field>
        </v-col>
<!--5-->
        <v-col cols="12" md="4">
          <v-combobox
            persistent-hint
            :items="[0.9, 1.0, 1.2, 1.4 ]"
            label="4 ¿Ancho del Mulch (Mts) ?"
            hint="¿Ancho del Mulch (Mts) ?"
            single-line
            :value="sheet5Phase1Section3FormField.measuresOfTheMulch"
            v-on:change="setHugoFormFields('measuresOfTheMulch', $event)"
            clearable
            :rules="[sharedValidations.requiredValidation]"
          ></v-combobox>
        </v-col>
<!--6-->
        <v-col cols="12" sm="4">
          <v-radio-group
            label="5 ¿Es necesario realizar control de arvenses antes de realizar la labor de cobertura?"
            hint="¿Es necesario realizar control de arvenses antes de realizar la labor de cobertura?"
            persistent-hint
            row
            :value="sheet5Phase1Section3FormField.necessaryChemical"
            v-on:change="setHugoFormFields('necessaryChemical', $event)"
            clearable
            :rules="[sharedValidations.requiredValidation]"
          >
            <v-radio label="Si" value="yes"></v-radio>
            <v-radio label="No" value="no"></v-radio>
          </v-radio-group>
        </v-col>
<!--7-->
        <v-col cols="12" md="4">
          <v-text-field
            persistent-hint
            label="6 ¿Diseño - Distancias perforaciones?"
            hint="¿Diseño - Distancias perforaciones?"
            required
            :rules="[sharedValidations.requiredValidation]"
            :value="sheet5Phase1Section3FormField.distanceBetweenHoles"
            v-on:change="setHugoFormFields('distanceBetweenHoles', $event)"
            clearable
          ></v-text-field>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import {HugoValidations} from "../partners/hr_co/sheet_5/validations/basicValidations";

export default {
  name: "Phase2Section3",
  data() {
    return {
      valid: false,
      formFields: {},
      sharedValidations: new HugoValidations(),
    };
  },
  methods: {
    validate() {
      this.$refs.form3.validate();
    },
    setHugoFormFields(name, value) {
      this.$store.commit('setHugoFormFields',{formType: 'sheet5Phase1Section3',name, value})
    }
  },
  computed: {
    sheet5Phase1Section3FormField() {
      return this.$store.getters.hugoFormFields.sheet5Phase1Section3
    },
  },
};
</script>

<style scoped>
</style>
