import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/login/Home.vue'

import LocationDetail from '@/views/login/LocationDetail.vue'

import CreateFarmer from '@/views/farmers/Create.vue'
import EditFarmer from '@/views/farmers/Edit.vue'

import CreateContact from '@/views/contacts/Create.vue'

import Customers from '@/views/customers/Index.vue'
import ShowCustomer from '@/views/customers/Show.vue'
import EditCustomer from '@/views/customers/Edit.vue'
import PrintCustomer from '@/views/customers/Print.vue'
import EditDemographics from '@/views/customers/EditDemographics.vue'
import EditCustomerLocation from '@/views/customers/EditLocation.vue'
import EditUzimaUgDetail from '@/views/customers/partners/uzima_ug/Edit.vue'
import VaOrder from '@/views/customers/partners/uzima_ug/VaOrder.vue'

import NewInputOrder from '@/views/inputOrders/New.vue'

import NewReceipt from '@/views/receipts/New.vue'
import ShowReceipt from '@/views/receipts/Show.vue'

import NewPurchase from '@/views/purchases/New.vue'
import ShowPurchase from '@/views/purchases/Show.vue'

import NewDelivery from '@/views/deliveries/New.vue'
import ShowDelivery from '@/views/deliveries/Show.vue'

import CreateAction from '@/views/actions/Create.vue'

import NewCustomerAppointment from '@/views/customers/NewAppointment.vue'

import Field from '@/views/fields/Show.vue'
import FieldEdit from '@/views/fields/Edit.vue'
import FieldDelete from '@/views/fields/Delete.vue'
import FieldScore from '@/views/fields/components/Score';
import FieldMap from '@/views/fields/Map.vue'

import UzimaUgAgentScore from '@/views/partners/uzima_ug/ScoreAgent';

import VoucherRedemption from '@/views/vouchers/Redeem';

import SkuIndex from '@/views/skus/Index';

import Photos from '@/views/photos/Index.vue'
import Search from '@/views/search/Index.vue'

import HRFieldScore from '@/views/partners/hr_co/Score.vue'
import HRContractDetail from '@/views/partners/hr_co/ContractDetail.vue'
import HRPhaseOne from '@/views/fields/partners/hr_co/PhaseOne.vue'
import HRFirstVisit from '@/views/partners/hr_co/FirstVisit.vue'
import FieldContractRegister from '@/views/partners/hr_co/FieldContractRegister.vue'
import SeedlingsAndTransplant from '@/views/partners/hr_co/sheet_5/seedlingandtransplant/SeedlingsAndTransplant.vue'
import GrowingAndApplications from '@/views/partners/hr_co/sheet_5/growingandapplications/GrowingAndApplications.vue'

import RegisterTree from '@/views/trees/New.vue'
import Trees from '@/views/trees/Index.vue'
import TreeFinder from '@/views/trees/Search.vue'

import PusherTest from '@/views/test/Pusher.vue'
import PreparationOfField from '@/views/section_2_form/PreparationOfField.vue'
import Phase4View from "../views/partners/hr_co/sheet_5/Phase4View";

import NileRegisterContact from '@/views/partners/nile_ug/NileStepperRegistrationForm'


Vue.use(VueRouter)

const routes = [
  { path: '/',                          name: 'Home',                     component: Home                         },

  { path: '/locationDetail',            name: 'LocationDetail',           component: LocationDetail               },

  { path: '/farmers/new',               name: 'CreateFarmer',             component: CreateFarmer                 },
  { path: '/farmers/edit',              name: 'EditFarmer',               component: EditFarmer                   },

  { path: '/contacts/new',              name: 'CreateContact',            component: CreateContact                },


  { path: '/customers',                 name: 'Customers',                component: Customers                    },
  { path: '/customers/show',            name: 'ShowCustomer',             component: ShowCustomer                 },
  { path: '/customers/edit',            name: 'EditCustomer',             component: EditCustomer                 },
  { path: '/customers/print',           name: 'PrintCustomer',            component: PrintCustomer                },
  { path: '/customers/demographics',    name: 'EditDemographics',         component: EditDemographics             },
  { path: '/customers/location',        name: 'EditCustomerLocation',     component: EditCustomerLocation         },
  { path: '/customers/uzima_ug',        name: 'EditUzimaUgDetail',        component: EditUzimaUgDetail            },
  { path: '/customer/uzima_ug/va/order', name: 'VaOrder',                 component: VaOrder                      },

  { path: '/customers/newAppointment',  name: 'NewCustomerAppointment',   component: NewCustomerAppointment       },


  { path: '/field',                     name: 'Field',                    component: Field }                      , 
  { path: '/field/edit',                name: 'FieldEdit',                component: FieldEdit,                   },
  { path: '/field/delete',              name: 'FieldDelete',              component: FieldDelete,                 },
  { path: '/mapField',                  name: 'FieldMap',                 component: FieldMap                     },
  { path: '/field/score',               name: 'FieldScore',               component: FieldScore,                  },

  { path: '/inputOrders/new',           name: 'NewInputOrder',            component: NewInputOrder,               },

  { path: '/receipts/new',              name: 'NewReceipt',               component: NewReceipt,                  },
  { path: '/receipt',                   name: 'ShowReceipt',              component: ShowReceipt,                 },

  { path: '/purchases/new',             name: 'NewPurchase',              component: NewPurchase,                 },
  { path: '/purchase',                  name: 'ShowPurchase',             component: ShowPurchase,                },


  { path: '/deliveries/new',            name: 'NewDelivery',              component: NewDelivery,                 },
  { path: '/delivery',                  name: 'ShowDelivery',             component: ShowDelivery,                },


  { path: '/actions/new',               name: 'CreateAction',             component: CreateAction                 },


  { path: '/hr/score',                  name: 'HRFieldScore',             component: HRFieldScore,                },
  { path: '/hr/contract',               name: 'HRContractDetail',         component: HRContractDetail,            },
  { path: '/hr/phaseOne',               name: 'HRPhaseOne',               component: HRPhaseOne,                  },
  { path: '/hr/firstVisit',             name: 'HRFirstVisit',             component: HRFirstVisit,                  },
  { path: '/hr/fieldContractRegister',  name: 'FieldContractRegister',    component: FieldContractRegister          },
  { path: '/hr/seedlingsAndTransplant', name: 'SeedlingsAndTransplant', component: SeedlingsAndTransplant},
  { path: '/hr/growingAndApplications', name: 'GrowingAndApplications', component: GrowingAndApplications},

  {path: '/nile/register', name: 'NileRegisterContact', component: NileRegisterContact},

  { path: '/trees/new',                 name: 'RegisterTree',             component: RegisterTree,                },
  { path: '/trees',                     name: 'Trees',                    component: Trees,                       },
  { path: '/trees/search',              name: 'TreeFinder',               component: TreeFinder,                  },

  { path: '/uzima_ug/asess_agent',      name: 'UzimaUgAgentScore',        component: UzimaUgAgentScore,           },

  { path: '/vouchers/redeem',           name: 'VoucherRedemption',        component: VoucherRedemption,           },


  { path: '/skus/index',                name: 'SkuIndex',                 component: SkuIndex,                    },


  { path: '/photos',                    name: 'Photos',                   component: Photos                       },
  { path: '/search',                    name: 'Search',                   component: Search                       },

  { path: '/push',                      name: 'Push',                     component: PusherTest                   },
  { path: '/preparation-of-field',                      name: 'PreparationOfField',                     component: PreparationOfField                   },
  { path: '/sheet5-phase4',                      name: 'Phase4View',                     component: Phase4View                   },

  { path: '/about',                     name: 'About', // route level code-splitting // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
