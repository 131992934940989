<template>
  <v-container fluid class="pa-0">

    <v-row v-if="customersInitialised">
      <v-col cols=12>

        <div v-if="customersInitialised && customers.length==0">
          <div class="mb-2">
            {{$t('noData')}}
          </div>
          <div>
            <v-btn elevation="2" small :to="{ name: 'CreateContact' }" color="primary">Create One</v-btn>
          </div>
        </div>
        
        <div class="pa-0 ma-0" v-else>

          <v-tabs v-model="tab" class="px-0">
            <v-tab v-if="tabs.includes('farmers') && farmers.length>0">
              <v-icon left>mdi-account-settings</v-icon> {{$t('farmers')}}
            </v-tab>
            <v-tab v-if="tabs.includes('contacts') && contacts.length>0">
              <v-icon left>mdi-account-question</v-icon> {{$t('contacts')}}
            </v-tab>
            <v-tab v-if="tabs.includes('agents') && agents.length>0">
              <v-icon left>mdi-teach</v-icon> {{$t('agents')}}
            </v-tab>
            <v-tab v-if="tabs.includes('nile_ug_applications') && nile_ug_applications.length>0">
              <v-icon left>mdi-account-tie-outline</v-icon> Drafts
            </v-tab>
            <v-tab v-if="tabs.includes('nile_ug_submissions') && nile_ug_submissions.length>0">
              <v-icon left>mdi-account-multiple-check-outline</v-icon> Submissions
            </v-tab>
            <v-tab v-if="tabs.includes('uzima_vas') && uzima_ug_vas.length>0">
              <v-icon left>mdi-account-tie-outline</v-icon> {{$t('uzima_vas')}}
            </v-tab>
            <v-tab v-if="tabs.includes('uzima_agents') && uzima_ug_agents.length>0">
              <v-icon left>mdi-bird</v-icon> {{$t('uzima_agents')}}
            </v-tab>
            <v-tab v-if="tabs.includes('all')" >
              <v-icon left>mdi-account</v-icon> {{$t('all')}}
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item v-if="tabs.includes('farmers') && farmers.length>0"><Farmers :farmers="farmers" /></v-tab-item>
            <v-tab-item v-if="tabs.includes('contacts') && contacts.length>0"><Contacts :contacts="contacts" /></v-tab-item>
            <v-tab-item v-if="tabs.includes('agents') && agents.length>0"><Customers :customers="agents" /></v-tab-item>
            <v-tab-item v-if="tabs.includes('nile_ug_applications') && nile_ug_applications.length>0"><NileUg :customers="nile_ug_applications" /></v-tab-item>
            <v-tab-item v-if="tabs.includes('nile_ug_submissions') && nile_ug_submissions.length>0"><NileUg :customers="nile_ug_submissions" /></v-tab-item>
            <v-tab-item v-if="tabs.includes('uzima_vas') && uzima_ug_vas.length>0"><UzimaUg :customers="uzima_ug_vas" /></v-tab-item>
            <v-tab-item v-if="tabs.includes('uzima_agents') && uzima_ug_agents.length>0"><UzimaUg :customers="uzima_ug_agents" /></v-tab-item>
            <v-tab-item v-if="tabs.includes('all')"><Customers :customers="customers" /></v-tab-item>
          </v-tabs-items>

        </div>

      </v-col>
    </v-row>

    <v-row align="center" justify="center" v-else>
      <v-col cols=3 />
      <v-col cols=6 align="center" justify="center" >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-col>
      <v-col cols=3 />
    </v-row>

  </v-container>
</template>


<script>

  import Customers from '@/views/customers/components/Index';
  import Farmers from '@/views/farmers/components/Index';
  import Contacts from '@/views/contacts/components/Index';
  import NileUg from '@/views/customers/partners/nile_ug/Index';
  import UzimaUg from '@/views/customers/partners/uzima_ug/Index';

  export default {

    components: {
      Customers,
      Farmers,
      Contacts,
      NileUg,
      UzimaUg,
    },

    data: () => ({
      tab: null,
    }),

    computed: {
      customersInitialised() { return this.$store.getters.customersInitialised; },
      customers() { return this.$store.getters.customers; },
      farmers() { return this.$store.getters.farmers; },
      contacts() { return this.$store.getters.contacts; },
      agents() { return this.$store.getters.agents; },
      uzima_ug_vas() { return this.$store.getters.uzima_ug_vas; },
      uzima_ug_agents() { return this.$store.getters.uzima_ug_agents; },
      nile_ug_applications() { return this.$store.getters.customers.filter(e => !e.nileUgApplication); },
      nile_ug_submissions() { return this.$store.getters.customers.filter(e => e.nileUgApplication); },

      partnerCode() { return this.$store.getters.settings.partnerCode; },
      tabs() {
        let t = ['farmers', 'contacts', 'agents', 'all'];
        if (this.partnerCode==='uzima_ug') {
          t = ['uzima_vas', 'uzima_agents', 'all'];
        }
        if (this.partnerCode==='nile_ug') {
          t = ['nile_ug_applications', 'nile_ug_submissions', 'all'];
        }
        if (this.partnerCode==='hr_co') {
          t = t.filter(e => e!='agents');
        }
        return t;
      },
    },

  }
</script>


<i18n>
{
  "en": {
    "noData": "There are no farmers, contacts or other profiles saved locally.",
    "farmers": "Farmers",
    "contacts": "Prospects",
    "agents": "Agents",
    "uzima_vas": "VAs",
    "uzima_agents": "Agents",
    "all": "All"
  },
  "es": {
    "noData": "No hay agricultores, contactos u otros perfiles guardados localmente.",
    "farmers": "Agricultores",
    "contacts": "Prospectos",
    "agents": "Técnico Agrícola",
    "uzima_vas": "VAs",
    "uzima_agents": "Técnico Agrícola",
    "all": "Todos"
  },
  "sw": {
    "noData": "Hakuna mkulima, mawasiliano au wasifu mwingine uliookolewa ndani.",
    "farmers": "Wakulima",
    "contacts": "Matarajio",
    "agents": "Mawakala",
    "uzima_vas": "VAs",
    "uzima_agents": "Kila Mtu",
    "all": "Kila Mtu"
  }
}
</i18n>

