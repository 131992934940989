<template>
  <v-card>

    <v-card-title>
      {{$t('title')}}
      <v-spacer />
      <v-btn color="primary" small dense @click="edit">{{$t('edit')}}</v-btn>
    </v-card-title>

    <v-card-text>
      <v-simple-table v-if="anyFields" >
        <template v-slot:default>
          <tbody>
            <Row :title="$t('farmName')"     :text="farmName"   v-if="farmName" />
            <Row :title="$t('contactType')" :status="customer.hrCoContactType"   v-if="customer.hrCoContactType" />
            <Row :title="$t('fieldAgent')"   :text="fieldAgent" v-if="fieldAgent" />
            <Row :title="$t('depot')"        :text="depotName"  v-if="depotName" />
            <Row :title="$t('landSize')" v-if="customer.landSize" >
              <LandSize :landSize="customer.landSize" />
            </Row>
            <Row :title="$t('primaryCrop')" :text="customer.primaryCrop"  v-if="customer.primaryCrop" />
          </tbody>
        </template>
      </v-simple-table>
      <div v-else>
        {{$t('noDetails')}}
      </div>
    </v-card-text>

  </v-card>
</template>


<script>

  import Row from '@/components/Row';
  import LandSize from '@/components/LandSize';

  export default {

    components: { Row, LandSize },

    props: ['customer'],

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      farmName()   { return (this.customer.farm ? this.customer.farm.name : null) },
      fieldAgent() { return (this.customer.fieldAgent ? this.customer.fieldAgent.name : null) },
      depotName() { return (this.customer.depot ? this.customer.depot.name : null) },

      anyFields() { return (this.fieldAgent || this.depotName || this.customer.landSize || this.customer.primaryCrop); },
    },

    methods: {
      edit() { this.$router.push({name: 'EditFarmer', query: { uuid: this.customerUuid }}); },
    },

  }
</script>


<i18n>
{
  "en": {
    "title": "Agriculture",
    "farmName": "Farm Name",
    "contactType": "Contact Type",
    "primaryCrop": "Primary Crop",
    "noDetails": "No details yet."
  },
  "es": {
    "title": "Finca",
    "farmName": "Finca",
    "contactType": "Tipo de Contacto",
    "primaryCrop": "Variedad",
    "noDetails": "Aún no hay detalles."
  },
  "sw": {
    "title": "Kilimo",
    "farmName": "Jina la Shamba",
    "contactType": "Aina ya Mawasiliano",
    "primaryCrop": "Mazao ya Msingi",
    "noDetails": "Hakuna maelezo bado."
  }
}
</i18n>

